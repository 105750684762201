<template>
  <transition name="fade">
    <Form :title="titleForm"
      :btn="btnForm"
      class="center-center"
      :fields="dataForm"
      action=""
      method="post"
      @answerForm="login"
      v-if="showForm"
      />
    <div v-else class="block-spiner">
      <div class="spiner--colors">
        <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
      <span class="spiner--text">Выполнение операции...</span>
      <div class="spiner--colors">
        <i style="color:#fff" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#2567f3" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        <i style="color:#f32525" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import Form from '@/components/atoms/Form.vue';

export default {
  name: 'WBLogin',
  components: {
    Form,
  },
  data() {
    return {
      showForm: false,
      dataForm: [],
      titleForm: '',
      btnForm: '',

      loader: false,
      errors: [],
    };
  },
  computed: {
    ...mapGetters('auth', {
      getterLoginStatus: 'getLoginStatus',
      getterFields: 'getErrors',
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('auth', {
      actionLogin: 'login',
    }),
    init() {
      axios.get('/auth/login')
        .then((res) => {
          this.dataForm = res.data.pack;
          this.titleForm = res.data.form_info.name;
          this.btnForm = res.data.form_info.btn;
          this.showForm = true;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    login(fields) {
      this.actionLogin(fields);
      this.showForm = false;

      setTimeout(() => {
        if (this.getterLoginStatus === 'success') {
          const user = localStorage.getItem('user');
          if (user) {
            axios.defaults.headers.common.Authorization = `Bearer ${user}`;
          }
          localStorage.removeItem('stock');
          const path = localStorage.getItem('start');
          this.$router.push(path);
        } else {
          this.dataForm = this.getterFields;
          setTimeout(() => {
            this.showForm = true;
          }, 100);
        }
      }, 1200);
    },
  },
};
</script>

<style scoped>
  .center-center{
    padding: 50px;
    background: #ccc;
    position: fixed; top: 50%; left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .block-spiner{
    position: fixed;
    left: 50%;
    top: 50%;
    background: #dedede;
    width: 320px;
    height: 300px;
    margin-left: -160px;
    margin-top: -150px;
    text-align: center;
  }
  .spiner--text{
    font-size: 20px;
    line-height: 100px;
  }
  .spiner--colors{
    font-size: 30px;
    line-height: 100px;
  }
  .spiner--colors i{
    padding: 0px 14px;
  }
</style>
