<template>
  <div class="wrapper-burger" v-click-outside="closeMenu">
    <button :title="params.title"
    @click="turnMenu" :class="[{active_button: isActive === 1}, params.button]">
      <i class="fa" :class="params.icon"></i>
    </button>
    <transition name="bottom-menu">
      <div v-if="isActive === 1" ref="menu"
        :class="{nav: !changePositionMenu, 'nav-bottom': changePositionMenu}">
        <ul v-if="params.list">
          <template v-for="(el, index) in params.list" :key="index">
            <li @click="applyLoader(el)" @keyup.page-down="rechange"
              v-if="el.type === 'AD'" >
              <i class="fa icon_li" :class="el.icon"></i>{{el.title}}
            </li>
            <li @click="applyLoader(el)" @keyup.page-down="rechange"
              v-if="el.type === 'AL'" >
              <i class="fa icon_li" :class="el.icon"></i>{{el.title}}
            </li>
            <li @click="showComponent(el)" @keyup.page-down="rechange"
              v-if="el.type === 'ML'" >
              <i class="fa icon_li" :class="el.icon"></i>{{el.title}}
            </li>
            <li @click="showParentComponent(el)" @keyup.page-down="rechange"
              v-if="el.type === 'OP'" >
              <i class="fa icon_li" :class="el.icon"></i>{{el.title}}
            </li>
            <li v-if="el.type === 'GL'">
              <i class="fa icon_li" :class="el.icon"></i>
              <router-link :to="el.action">{{el.title}}</router-link>
            </li>
          </template>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MenuCollapse',
  props: {
    params: {
      type: Object,
      default: () => {},
      required: true,
    },
    ind: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      isActive: 0,
      changePositionMenu: false,
    };
  },
  mounted() {
  },
  methods: {
    turnMenu() {
      if (this.isActive === 0) {
        this.isActive = 1;
      } else {
        this.isActive = 0;
      }

      this.changePositionMenu = false;
      this.$nextTick(() => {
        if (this.isActive) {
          const menuLineBottom = this.$refs.menu.getBoundingClientRect().bottom;
          const talbeLineBottom = document.getElementsByClassName('table')[0].getBoundingClientRect().bottom;
          const windowLineBottom = window.innerHeight;

          if (menuLineBottom > talbeLineBottom || menuLineBottom > windowLineBottom) {
            this.changePositionMenu = true;
          } else {
            this.changePositionMenu = false;
          }
        }
      });
    },
    showParentComponent(el) {
      this.isActive = 0;
      this.$emit('show-parent-component', {
        component: 'Operations',
        conf: el,
        w: '',
        ind: this.ind,
      });
    },
    showComponent(el) {
      this.isActive = 0;
      this.$emit('show-component', {
        component: el.component,
        conf: el.conf,
        w: (el.w ? el.w : 'w100'),
        ind: this.ind,
      });
    },
    closeMenu() {
      this.isActive = 0;
    },
    applyLoader(el) {
      this.isActive = 0;

      axios.post(el.action, el.params)
        .then((res) => {
          if (res.data.success === 1) {
            this.$emit('chg-list', {
              act: el.act,
              ind: this.ind,
              elem: res.data.elem,
            });
          } else {
            alert(res.data.answer);
          }
        }).catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.right .nav {
  display: block;
  position: absolute;
  z-index: 1;
  background-color: white;
  animation: burgerAnimation 0.4s;
  border: 1px solid #dadee0;
  box-shadow: 0px 0.5rem 1rem 0px rgb(28 36 40 / 18%);
  top: -8px;
  bottom: unset;
  left: 51px;
  border-radius: 2px;
}
.right .nav-bottom {
  display: block;
  position: absolute;
  z-index: 1;
  background-color: white;
  animation: burgerAnimation 0.4s;
  border: 1px solid #dadee0;
  box-shadow: 0px 0.5rem 1rem 0px rgb(28 36 40 / 18%);
  left: 51px;
  top: unset;
  bottom: -7px;
}

.small .button-td{
  padding: 6px 15px 6px 30px;
}
.small .nav{
  top: 20px;
}
.bottom-menu-enter-active {
  transition: all 0.3s ease-out;
}

.bottom-menu-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.bottom-menu-enter-from,
.bottom-menu-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.active_button {
/*  background-color: #f6f8fa;*/
  background-color: white;
/*  border: 1px solid #dadee0;*/
/*  box-shadow: 0px 0.5rem 1rem 0px rgb(28 36 40 / 18%); */
/* было 12 14 9 29 */
/*  padding: 9px 14px 9px 29px;*/
}

li .icon_li {
  margin-right: 12px;
}
button {
  border: none;
  cursor: pointer;
/*  было по 10 и 15*/
  margin: -7px -7px;
  padding: 10px 15px 10px 30px;
}
.button-td {
  background-color: #ffffff;
}
.button-th {
  background-color: #f6f8fa;
}
.nav {
  display: block;
  position: absolute;
  z-index: 1;
  background-color: white;
  animation: burgerAnimation 0.4s;
  border: 1px solid #dadee0;
  box-shadow: 0px 0.5rem 1rem 0px rgb(28 36 40 / 18%);
  top: 29px;
  border-radius: 0px 5px 16px 0px / 0px 5px 18px 0px;
}
.wrapper-burger {
  margin-left: -15px;
  position: relative;
/*  margin-top: -15px;*/
}
ul {
  list-style-type: none;
  padding: 8px 0px;
  margin: 0px 0px;
}
/*li:first-child {
  padding: 10px 20px;
  padding-top: 20px;
}
li:last-child {
  padding: 10px 20px;
  padding-bottom: 20px;
}*/
li {
/*  margin-bottom: 12px ;*/
  font-weight: normal;
  padding: 12px 20px;
  font-size: 12px;
  cursor: pointer;
}
li:hover {
  background-color: #eaeef1;
}
</style>
