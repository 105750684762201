<template>
  <div class="wb-group-check" :class="{'error-input': err, 'req': req}">
    <div class="label" :class="{active: showList}" v-if="lbl">{{lbl}}</div>
    <div class="wb-group-list">
      <div class="list--item" v-for="(el, ind) in list" :key="ind">
        <CheckDef :lbl="el.lbl" v-model="el.chk"
          @update:modelValue="selectOption"></CheckDef>
        </div>
    </div>
  </div>
</template>

<script>
import CheckDef from '@/components/v2/cross/form/fields/CheckDef.vue';

export default {
  name: 'SelectMultyCheck',
  components: {
    CheckDef,
  },
  props: {
    lbl: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [
        {
          val: 0,
          lbl: 'Пустое поле',
        },
      ],
    },
    def: {
      type: String,
      default: '',
    },
    err: {
      type: String,
      default: '',
    },
    req: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      list: this.options,
    };
  },
  methods: {
    selectOption() {
      this.$emit('update:modelValue', this.list);
      this.$emit('change');
    },
    onClose() {
      this.$emit('outError', this.ind);
    },
  },
};
</script>

<style scoped>
.list--item{
  display: inline-block;
  margin-right: 20px;
}
</style>
