<template>
  <div class="wb-checkbox">
    <div class="squad" :class="{active: active}"
      @click="check" @keydown="check">
        <div class="text">{{ text[0] }} &nbsp;</div >
        <div class="text">{{ text[1] }}</div>
      <span class="checked" :class="{active: active}"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props: [
    'text',
    'err',
    'modelValue',
  ],
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      active: false,
    };
  },
  computed: {
    checkbox() {
      let flag = false;
      if (this.modelValue === '1' || this.modelValue === 1 || this.modelValue === true) {
        flag = true;
      }

      return flag;
    },
  },
  methods: {
    check() {
      this.active = !this.active;
      let flag = 1;
      if (this.checkbox === true) {
        flag = 0;
      }
      this.$emit('update:modelValue', flag);
      this.$emit('change');
    },
  },
};
</script>

<style scoped>
.wb-checkbox {
  display: inline-block;
  cursor: pointer;
  color: #000;
  background: transparent;
}
.squad {
  display: inline-block;
  position: relative;
  top: -2px;
  vertical-align: top;
  transition: .2s;
  background-color: #f6f8fa;
  border: 1px solid #dadee0;
  margin-left: 2px;
}
.checked {
  content: '';
  display: inline-block;
  width: 76px;
  height: 26px;
  position: absolute;
  top: -1px;
  left: -1px;
  background-color: #bcede855;
  transition: .15s;
  padding: 0;
  border: 1px solid #84afaa;
}
.checked.active {
  transform: translateX(76px);
  width: 94px;
}
.text {
  display: inline-block;
  white-space: nowrap;
  padding: 0px 4px;
  font-size: 14px;
  line-height: 16px;
}
</style>
