<template>
  <div class="block-list">
    <div class="lvl" v-if="lbl.length" style="padding-left: 6px">{{lbl}}</div>
    <div class="block-list-head">
      <input v-model="word" @input="search" />
    </div>
    <div class="body-scroll" v-if="mode === 1">
      <div class="level" :class="`level-${el.level}`" v-for="(el, ind) in items" :key="ind">
        <div class="item" v-if="el.show"
          :class="{'ierarh-border': el.level > 0}">
          <div v-if="el.level > 0" class="squad-level"></div>
          <span class="el">
            <span :class="{'pointer': el.child || dynamic && el.cnt != 0 || el.articles}"
              class="box outter-big folder" style="float:left; display:block;"
              @click="toggleChild(ind)"
              @keydown.f9="toggleChild(0)">
              <template v-if="el.child || dynamic && el.cnt != 0">
                <i v-if="el.open" class="fa fa-folder-open" aria-hidden="true"></i>
                <i v-else class="fa fa-folder" aria-hidden="true"></i>
              </template>
              <span v-else-if="el.articles">
                <i v-if="!el.open" class="fa fa-folder" aria-hidden="true"></i>
                <i v-else class="fa fa-folder-open" aria-hidden="true"></i>
              </span>
              <span v-else class="empty-squad">
                <i class="fa fa-folder" aria-hidden="true"></i>
              </span>
            </span>
            <span class="box outter category pointer">
              <Checkbox
                v-model="el.chk"
                @change="checkElem(ind, 1)"
                class="category"
              />
            </span>
            <span class="text-box"
              :class="{'active': el.chk}"
              :title="`${el.lbl} [${el.cnt}]`"
            >
              <span class="text">{{ el.lbl }}</span>
              <span class="number">{{ el.cnt }}</span>
            </span>
          </span>
          <div v-if="el.open && el.articles" class="pack-articles level"
            :class="`level-${el.level + 1}`">
            <div class="article-one" v-for="(articul, ind2) in el.articles" :key="ind2">
              <div v-if="el.level > 0" class="squad-level"></div>
              <span class="box outter-big folder" style="float: left; display: block;">
                <span><i class="fa fa-cube" aria-hidden="true"></i></span>
              </span>
              <span class="box outter pointer"
                :class="{active: articul.chk}"
                :title="`${articul.lbl} [${articul.cnt}]`"
                @click="changeArticles(ind, ind2)"
                @keydown.f9="changeArticles(0)">
                <span class="text">{{articul.lbl}}</span>
                <span class="number">{{articul.cnt}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body-scroll" v-else>
      <div class="row" v-for="(el, ind) in items" :key="ind">
        <span class="box outter category pointer">
          <Checkbox
            v-model="el.chk"
            class="category"
            @click="checkElem2"
          />
        </span>
        {{el.lbl}}
      </div>
    </div>
  </div>
</template>
<script>
import Checkbox from '@/components/v2/serj/form/fields/CheckDef.vue';

export default {
  name: 'ListBySearchCheck',
  components: {
    Checkbox,
  },
  props: {
    lbl: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    mode: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      items: this.list,
      word: '',
    };
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    search() {
      // this.word
    },
    checkElem2() {
      this.$emit('changeStage', { items: this.items, tab: this.tab });
    },
    checkElem(ind, checkbox = 0) {
      if (ind < 0) return;

      // вынести в отдельную функцию, бизнес-логика, однако
      if (this.onlyOne === 1 || this.onlyOne === true) {
        this.cats = [];
        this.uncheckAll();
        if (this.items[ind]) {
          this.items[ind].chk = true;
          this.cats.push(this.list[ind].val);
        }
        // this.$emit('transferCats', this.cats);
        return;
      }

      this.level = this.items[ind].level;
      this.flag = 0;
      if (checkbox === 0) {
        this.items[ind].chk = !this.items[ind].chk;
      }

      // let indItem;
      let BreakException;
      const { chk } = this.items[ind];
      try {
        this.items.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level >= item.level) {
              throw BreakException;
            }
            this.items[i].chk = true;
          }

          if (this.flag === 2) {
            if (this.level >= item.level) {
              throw BreakException;
            }
            this.items[i].chk = false;
          }

          if (ind === i) {
            if (chk) {
              this.flag = 1;
            } else {
              this.flag = 2;
            }
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      this.cats = [];
      this.items.forEach((el) => {
        if (el.chk === true || el.chk === 1) {
          this.cats.push(el.val);
        }
      });

      this.uncheckAllArticles();
      this.articles = [];
      this.$emit('changeStage', { items: this.items, tab: this.tab });
    },
    toggleChild(ind) {
      if (this.items[ind].child === false) {
        if (this.items[ind].articles) {
          this.items[ind].open = !this.items[ind].open;
        }
        return;
      }
      this.level = this.items[ind].level;
      this.flag = 0;
      this.open = this.items[ind].open;
      let BreakException;
      try {
        this.items.forEach((item, i) => {
          if (this.flag === 1) {
            if (this.level === item.level) {
              throw BreakException;
            }
            // изменить show
            if (this.open === true) {
              if (this.items[i].level > this.level) {
                this.items[i].show = false;
                this.items[i].open = false;
              }
            } else if ((this.level + 1) === item.level) {
              this.items[i].show = true;
            }
          }

          if (ind === i) {
            this.flag = 1;
            // изменить open
            this.items[i].open = !this.items[i].open;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
    uncheckAllArticles() {
      this.items.forEach((el) => {
        if (el.articles) {
          el.articles.forEach((el2) => {
            el2.chk = false;
          });
        }
      });
    },
    saveCatsToStock() {
      let index;
      this.confirm.btns.forEach((el, ind) => {
        if (el.tp === 'action') {
          index = ind;
        }
      });
      this.confirm.btns[index].param = {
        stock: this.stock,
        cats: this.cats,
      };
    },
  },
};
</script>

<style scoped>
.block-list-head{
  padding-left: 6px;
  /* padding-right: 6px; */
  background-color: #fff;
  padding-top: 6px;
}
.block-list-head input{
  background-color: #fff;
}
.row{
  font-size: 14px;
  line-height: 23px;
}
.number{
  background-color: #fff;
  border: 1px solid #aaa;
  margin-left: 5px;
  padding: 0px 4px;
}
.body-scroll{
  height: calc(100% - 50px);
  overflow-y: scroll;
  background-color: #ffffff;
  padding-top: 4px;
  padding-left: 6px;
  padding-right: 6px;
}
.text-box{
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  padding: 0px 5px;
}
.empty-list-btn{
  padding: 0px;
  background-color: #f6f8fa;
  box-shadow: none;
  border: 1px solid #dadee0;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow .25s, background-color 0.25s;
  font-size: 14px;
}
.empty-list-btn:hover{
  background-color: #f2f2f2;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.f-icon{padding: 10px 10px; background-color: #e2e5e8;}
.t-icon{padding-left: 10px;}
.empty-btn-text{
  padding: 10px;
}
.all-badge{
  position: absolute;
  right: 0px;
  top: 0px;
}
.wb-input-text.input-cu{
  border-bottom: 1px solid #aaa;
  display: inline-block;
  position: relative;
}
.block-list{
  height: 100%;
}
.ext-settings_checkbox {
  display: inline-block;
  font-size: 14px;
  background-color: #ffffff;
  color: #1c2428;
  border: 1px solid #dadee0;
  line-height: 14px;
  padding: 4px 6px;
  margin-left: 50px;
}
.counter {
  display: inline-block;
  /* position: absolute; */
  font-size: 11px;
  margin-right: 10px;
  margin-bottom: -1px;
}
.counter_el {
  background: #f6f8fa;
  border: 1px solid #e2e6e8;
  padding: 6px;
}
.counter_el.left {
  margin-right: 5px;
}
.button-table {
  text-align: center;
  cursor: pointer;
  padding: 11px;
  margin: -7px;
}
.button-table:hover {
  background: #dfe4e6;
}
.button-table.success {
  background: #95e2ac;
  color: #14632c;
}
.changed-cu{
  position: absolute;
  top: -8px;
  right: 0px;
  border-radius: 2px;
  font-size: 11px;
}
.changed-cu.plus {
  /*
  color: #97e7c1;
  background-color: #193629e8;
  border: 1px solid #1d5e3f;
  padding: 0px 3px;
  */

  color: #236a41;
  background-color: #c7fbe3e8;
  padding: 0px 3px 1px;
}
.changed-cu.minus {
  color: #ffa9a9;
  background-color: #361a19e8;
  border: 1px solid #a00909;
}
.wb-input-text{
  position: relative;
  margin-left: 0px;
  /* padding-top: 16px; */
  display: inline;
}
.label{
  color: #909090;
  display:inline-block;
  position: absolute;
  top: 24px; left: 0px;
  transition: top 0.3s, left 0.3s, font-size 0.3s, color 0.3s;
}
input {
  display: inline-block;
  width: 100%;
  border: none;
  border: 1px solid #aaa;
  /* font-size: 13px; */
  line-height: 18px;
  outline: none;
  box-sizing: border-box;
  background: transparent;
  transition: border-bottom-color 0.3s, background-color 0.3s;
}
.list-manage_button {
  cursor: pointer;
  padding: 2px 6px 1px 6px;
  border-bottom: 1px solid #aaa;
  text-align: center;
}
.list-manage_button:hover {
  background-color: #e8e8e8;
}

.context-menu-empty{
  top:0px; left:0px; right: 0px; bottom: 0px;
  text-align: center;
  opacity: 0.5;
}
.sub-sett > div{
  display: inline-block;
  vertical-align: top;
  background-color: #ffbebe;
  color: #2e1010;
  padding: 0px 3px;
  margin-right: 4px;
}
body table.table .progress.progress-striped{
  min-width: 180px;
  max-width: 260px;
}
table.table {
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
}
.table{
   background: #fff;
}
.container-up-table {
  display: flex;
  position: sticky;
  align-items: flex-end;
  flex-direction: column;
}
tr.disabled td{
  opacity: 0.3;
}
tr.disabled td:first-child{
  opacity: 1;
}

/*
table.table {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow .25s;
}
table.table:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
*/

.links {
}
.links_el {
}
.select-checkbox {
}
.hover {
  cursor: pointer;
}
.i-color {
  color: #b4b8bb;
}
.fa-share {
  transform: rotate(180deg);
  transform:scale(1, -1);
/*  transition: width 0.1s ease-out;*/
}

.slide-form-leave-active {
  transition: width 0.1s ease-in;
}
.slide-form-enter-from, .slide-form-leave-to{
  width: 200%;
}
.slide-form-enter-to, .slide-form-leave-from{
  width: 100%;
}
.instrumental-new {
  display: flex;
  position: sticky;
  top: 50px;
  width: 100%;
  justify-content: flex-end;
}
.instrumental-new .btn-add-new {
    background: #f6f8fa;
    font-size: 13px;
    padding: 6px;
    display: inline-block;
    cursor: pointer;
    top: 1px;
    border: 1px solid #dadee0;
    position: relative;
    transition: all 0.3s;
}
.instrumental-new .btn-add-new:hover {
    border: 1px solid #6dc2ed;
}
.wrapper-pagination {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 0px;
  bottom: -2px;
}
.pagination-new {
  display: flex;
/*  background: #f6f8fa;*/
/*  border-radius: 7px;*/
/*  padding: 5px 5px;*/
/*  border: 1px solid #dadee0;*/
}
/* Begin */
.pagination-item {
  list-style-type: none;
  display: inline-block;
  border-top: 1px solid #d7dadb;
  border-right: 1px solid #d7dadb;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 14px;
  color: #8b969c;
  background-color: white;
  cursor: pointer;
}
.pagination-item:first-child {
  border-left: 1px solid #d7dadb;
}

.pagination-item:hover {
  background-color: #DCDDE1;
  border-top: 1px solid #DCDDE1;
  border-right: 1px solid #DCDDE1;
}
.pagination-item.active {
  background-color: #bcede8;
  border-top: 1px solid #bcede8;
  border-right: 1px solid #bcede8;
  color: #333;
}

.pagination-item.first-number {
  border-left: 1px solid #d7dadb;
}

.pagination-link {
  padding: 1.1em 1.6em;
  display: inline-block;
  text-decoration: none;
  color: #8b969c;

  transform: scale(1) rotate(0deg) translateX(0px) translateY(0px) skewX(20deg) skewY(0deg);
}
/* End */
.pagination-new .pag-el-new.active {
/*    background-color: #44b752;*/
/*    border: 1px solid #2c9539;*/
  border: 1px solid #6dc2ed;
  background-color: #f6f4f4;
/*  background-color: #0000003d;*/
}
.pagination-new .pag-el-new {
/*    vertical-align: top;*/
    /* background-color: #749be5; */
    /* border: 1px solid #4c7fe1; */
    /* color: #fff; */
    cursor: pointer;
    padding: 12px 12px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border-radius: 100px;
    transition: all 0.3s;
    border: 1px solid #f6f8fa;
}
.pagination-new .pag-el-new:hover {
  border: 1px solid #6dc2ed;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.short-input-filter{
  max-width: 200px;
  min-width: 100px;
}
.table-filter .short-input-filter{
  max-width: 100%
}
.table {
/*  width: 100%;*/
/*  border-left: 1px solid #dadee0;*/
  border-collapse: collapse;
/*  border-spacing: 0px;*/
}
.table thead {
  position: sticky;
  top: 0px;
  z-index: 1;
}
.table thead th {
  font-weight: 100;
  text-align: left;
  background: #f6f8fa;
  font-size: 11px;
  white-space: nowrap;

  padding: 0;
  margin: 0;
  vertical-align: top;
}
.table thead tr:last-child th{
  /* border-bottom: 1px solid #6dc2ed; */
}
.table thead tr:nth-child(n+2) th:first-child{
/*  border-right: 1px solid #dadee0;*/
  text-align: center;
}
.table thead tr th:first-child {
  border-top: none;
  border-left: none;
  width: 12px;
/*  border-radius: 8px 0 0 8px;*/

}
.table thead tr th:last-child {
/*  border-radius: 0 8px 8px 0;*/
  width: 100%;
}
.th-inner{
  border-right: 1px solid #dadee0;
  border-top: 1px solid #dadee0;
  padding: 7px 7px;
  height: 13px;

}
.th-inner.two-line{
  height: 21px;
  border-bottom: 1px solid #6dc2ed;
}
.fa-filter{
  font-size: 16px; line-height: 21px;
}
.table tbody td{
  text-align: left;
  border-bottom: 1px solid #dadee0;
  padding: 7px 7px;
  font-size: 13px;
  white-space: nowrap;
}
.table tbody tr:nth-child(even){
/*  background: #fdfcfccc;*/
}
.table tbody tr td:nth-child(2){
  border-right: 1px solid #dadee0;
  margin-bottom: 100px;
/*  width: 110px;*/
}

.table tbody tr td:nth-child(n+3) > div{
/*  border-right: 1px solid #dadee0;*/
  overflow: hidden;
  position: relative;
  display: block;
  padding-right: 5px;
  max-width: 500px;
  min-width: 100%;
}
.table tbody tr td:nth-child(n+3) > div:before{
  content: '';
  width: 10px;
  height: 30px;
  position: absolute;
  right: 0;
  background: linear-gradient(90deg,
    rgb(255 255 255 / 0%) 25%,
    rgb(255 255 255 / 69%) 50%,
    #ffffff 100%);
}
.table tbody tr td:first-child {
/*  border-radius: 8px 0 0 8px;*/
}
.table tbody tr td:last-child {
/*  border-radius: 0 8px 8px 0;*/
}
.table tr:hover {
  background-color: #fdfcfccc;
}
input[type=text] {
    border:none;
    background:none;
    outline:none;
    padding:0;
    font-size: 14px;
    font-weight: 100;
    color: #2c3e50;
}

.tab-el, .tab-el2 {
  border: 1px solid #f6f8fa;
  border-radius: 8px 0 0 8px;
  background-color: #f6f8fa;
  display: inline-block;
  padding: 10px 15px;
}

.tab-el {
}

.tab-el2 {
  margin: 0px 0px 15px 0px;
}
.field-icon{font-size: 18px;}
.field-icon .fa-check{color: green;}
.field-icon .fa-times{color: red;}

.ext-settings{
  overflow: hidden;
  /* width: 100%; */
}

.list-empty{
  display: flex;
  height: 100%;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;

  /* background: #ccc; */
  color: #1e1e1e;
  font-size: 16px;
}
.list-empty .text-empty{
  /* background: #fff; */
  text-align: center;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px #fff;

  background-color: #e2e5e8;
  padding: 6px 12px;
}
.new td {background-color: #e9faec}

.table tbody tr.new td div:before{
background: linear-gradient(90deg, rgb(233 255 236 / 0%) 25%,
rgb(233 255 236 / 69%) 50%, #e9faec 100%);
}

.el .box.folder {
  position:relative;
  z-index: 1;
  width: 20px;
  min-width: 20px;
  text-align: center;
  background-color: #EDEFF1;
}
.box.outter-big {
  margin-right: 2px;
}
.el .box, .box {
  font-size: 12px;
  line-height: 14px;
  padding: 3px 0px;
  border-radius: 1px;
  background-color: #F6F8FA;
  color: #444;
  z-index: 1;
  border: 1px solid #dadee0;
  /* align-items: center; */
}

.pointer {
  cursor: pointer;
}

.empty-squad{width: 12.5px; height:12px; display: inline-block;}
.squad-level{
  width: 14px;
  height: 12px;
  display: inline-block;
  vertical-align: top;
  margin-right: -5px;
  position: relative;
  float: left;
  left: 3px;
}
.squad-level:before{
  content: '';
  display: block;
  width: 126%;
  border-top: 1px solid #a7a7a7;
  position: absolute;
  top: 95%;
  left: 0px;
  background-color: #a7a7a7;
  height: 1px;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
}
.squad-level:after{
  content: '';
  display: block;
  width: 1px;
  /* border-top: 1px solid #a7a7a7; */
  position: absolute;
  top: -106%;
  left: 0px;
  height: 200%;
  background-color: #a7a7a7;
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); */
}

.item{
 margin-bottom: 2px;
}
.item .fa-folder-open{
  margin-right: -2px;
}
.item .fa-folder{ color: #009688; }
.item .fa-folder-open{ color: #009688;}
.item .empty-squad .fa-folder{ color: gray;}

.squad + span {
  display: block;
  margin-left: 24px;
  line-height: 22px;
}
.level {
  position:relative;
}

.level-1{padding-left: 5px}
.level-2{padding-left: 19px}
.level-3{padding-left: 33px}
.level-4{padding-left: 47px}
.level-5{padding-left: 61px}
.level-6{padding-left: 75px}
.level-7{padding-left: 89px}
.level-8{padding-left: 103px}
.level-9{padding-left: 117px}
.level-10{padding-left: 131px}

.border {
  content: '';
  display: block;
  width: 1px;
  border-top: 1px solid #a7a7a7;
  position: absolute;
  top: -78%;
  height: 174%;
  background-color: #a7a7a7;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.border-2 {left: 8px}
.border-3 {left: 28px}
.border-4 {left: 48px}
/* .level-2::after{
  content: '';
  display: block;
  width: 1px;
  border-top: 1px solid #a7a7a7;
  position: absolute;
  top: -78%;
  left: -17px;
  height: 174%;
  background-color: #a7a7a7;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
} */

.level-3::after{}
.level-4::after{}
.level-5::after{}
.level-6::after{}
.level-7::after{}
.level-8::after{}
.level-9::after{}
.level-10::after{}
.level-1 .squad + span,
.level-2 .squad + span,
.level-3 .squad + span,
.level-4 .squad + span,
.level-5 .squad + span,
.level-6 .squad + span,
.level-7 .squad + span,
.level-8 .squad + span,
.level-9 .squad + span,
.level-10 .squad + span{margin-left: 39px;}
</style>
