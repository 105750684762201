<template>
  <transition name="fade">
    <Loader v-if="spiner" />
    <div v-else class="body-context">
      <div class="stock">Заказ на склад «{{info.stock}}»</div>
      <div class="progress progress-striped">
        <div class="progress-bar" role="progressbar"
        :class="info.buffer.opt.color"
        :aria-valuenow="info.buffer.lbl" aria-valuemin="0" aria-valuemax="100"
        :style="{width: info.buffer.opt.w_block}">
          <span class="sr-only">{{info.buffer.lbl}}</span>
        </div>
        <div class="buffer-lbl"
        :class="info.buffer.opt.color_text">{{info.buffer.val}}</div>
      </div>

      <table class="info">
        <tr v-for="(el, ind) in info.productInfo" :key="ind">
          <th>{{el.lbl}}</th><td>{{el.val}}</td>
        </tr>
      </table>

      <table class="table">
      <thead>
        <tr>
          <th>Поставщик</th>
          <th>Время пополнения</th>
          <th>Минимальная партия</th>
          <th>В корзину</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(el, ind) in info.suppliers" :key="ind">
          <td>{{el.name}}</td>
          <td>{{el.refill_time}}</td>
          <td>{{el.min_party}}</td>
          <td><label for="ind"><input id="ind" type="text" v-model="el.need" /></label></td>
        </tr>
      </tbody>
      </table>

      <button class="btn-cross" v-if="flagShowBtn && btn.title" @click="creOrder">
        {{btn.title}}
      </button>
      <transition name="fade">
        <div v-if="message.length > 0" class="form-answer"
          :class="{danger: !flagSuccess}">{{this.message}}</div>
      </transition>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';

import Loader from '@/components/v2/cross/loader/Loader.vue';

export default {
  name: 'OrderProduct',
  components: {
    Loader,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      info: [],
      btn: {},
      flagShowBtn: true,
      flagSuccess: 0,
      message: '',

      errors: [],
      spiner: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.action) return;
      axios.get(this.action)
        .then((res) => {
          this.info = res.data.info;
          this.spiner = false;
          this.btn = res.data.btn;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    creOrder() {
      axios.post(this.btn.action, { order: this.info.suppliers })
        .then((res) => {
          console.log(res.data);
          this.flagSuccess = res.data.success;
          this.message = res.data.answer;
          this.flagShowBtn = false;
          // if (res.data.success === 1) {
          // }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.progress.progress-striped{
  margin-bottom: 20px !important;
  width: 50%;
}
.body-context{
  padding-left: 14px;
  height: calc(100vh - 68px);
  position: relative;
  overflow-y: auto;
}
.btn-cross{
  border: none;
  border-radius: 2px;
  display: inline-block;
  font-size: 17px;
  height: 36px;
  line-height: 36px;
  outline: 0;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: #fff;
  background-color: #009688;
  text-align: center;
  letter-spacing: .5px;
  transition: 0.2s ease-out;
  cursor: pointer;
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: all 0.3s ease-out;

  position: fixed;
  right: 10px;
  bottom: 10px;
}
.btn-cross:hover{
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
table.table, table.info {
    border-spacing: 0px;
    border-collapse: collapse;
}
.table, table.info {
  border-collapse: collapse;
  margin-bottom: 20px;
}
.table {
  background: #fff;
  width: 99%;
  margin-bottom: 50px;
}

table.info{width: 50%;}
table.info td{
  padding: 7px;
  border-bottom: 1px solid #dadee0;
}
table.info th{
  font-weight: normal;
  text-align: left;
  background: #f6f8fa;
  white-space: nowrap;
  padding: 7px;
  border-bottom: 1px solid #dadee0;
}

.table thead th{
  font-weight: 100;
  text-align: left;
  background: #f6f8fa;
  font-size: 11px;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  vertical-align: top;

  border-right: 1px solid #dadee0;
  border-top: 1px solid #dadee0;
  padding: 7px 7px;
  height: 13px;
  border-bottom: 1px solid #6dc2ed;
}

.table tbody td{
  text-align: left;
  border-bottom: 1px solid #dadee0;
  padding: 7px 7px;
  font-size: 13px;
  white-space: nowrap;
}

.stock{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 13px;
}

input{
  display: inline-block;
  width: 100%;
  min-width: 200px;
  /* padding: 4px 6px; */
  /* border-radius: 0 4px 4px 0px; */
  /* border: 1px solid #aaa; */
  /* border-left: 4px solid #aaa; */
  font-size: 12px;
  line-height: 18px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #dadee0;
  transition: all 0.3s;
  padding-left: 3px;
}
input:focus{
  border-color: #999;
}

.form-answer{
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #bbf7cb;
  border: 1px solid #8fbd9c;
  padding: 13px 46px;
}
.form-answer.danger {
  background: #fddbdb;
  border: 1px solid #9d8989;
}
</style>
