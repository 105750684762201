<template>
  <div class="wb-input-diapazon" v-click-outside="closeSpoiler">
    <div class="collapse-head" @click="toggleDiapazon" @keydown.f7="toggleDiapazon">
      <div class="one-line grey"><i class="fa fa-calendar" aria-hidden="true"></i>{{dFirst}}</div>
      <div class="one-line"> - </div>
      <div class="one-line grey"><i class="fa fa-calendar" aria-hidden="true"></i>{{dSecond}}</div>
    </div>
    <div v-if="flagShowDiapazon" class="collapse-body" :class="position">
      <div class="one-line one-calendar">
        <div class="title">
          <div @click="nextMonth(0)" @keydown.right="nextMonth(0)" class="arr right">
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </div>
          <div @click="prevMonth(0)" @keydown.left="prevMonth(0)" class="arr left">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
          </div>
          <div class="select">{{months[currFMonth]}} {{currFYear}}</div>
        </div>
        <div class="week-days">
          <div class="week-day" v-for="(day, ind) in week" :key="ind">
            {{day}}
          </div>
        </div>
        <div class="month-days">
          <div class="days" v-for="(week, ind) in monthDaysF" :key="ind" >
            <div v-for="(day, ind2) in week" :key="ind2"
              @click="pickDay(day.val, ind2, 0)" @keydown.enter="pickDay(day.val, ind2, 0)"
              class="day" :class="{'active': day.type == 1, 'no-click': day.type == 2,
              'between': day.type == 3, 'alien': day.type == 4}">
              <span v-if="day.val > 0" >{{day.val}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="one-line one-calendar">
        <div class="title">
          <div @click="nextMonth(1)" @keydown.right="nextMonth(1)" class="arr right">
            <i class="fa fa-angle-right" aria-hidden="true"></i>
          </div>
          <div @click="prevMonth(1)" @keydown.left="prevMonth(1)" class="arr left">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
          </div>
          <div class="select">{{months[currSMonth]}} {{currSYear}}</div>
        </div>
        <div class="week-days">
          <div class="week-day" v-for="(day, ind) in week" :key="ind">
            {{day}}
          </div>
        </div>
        <div class="month-days">
          <div class="days" v-for="(week, ind) in monthDaysS" :key="ind" >
            <div v-for="(day, ind2) in week" :key="ind2"
              @click="pickDay(day.val, ind2, 1)" @keydown.enter="pickDay(day.val, ind2, 1)"
              class="day" :class="{'active': day.type == 1, 'no-click': day.type == 2,
              'between': day.type == 3, 'alien': day.type == 4}">
              <span v-if="day.val > 0" >{{day.val}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="wrp-btn" v-show="showBtnReady">
        <a class="btn-cross"
          @click="send" @keydown.left="send">Выбрать</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputDiapazon',
  props: {
    first: Number,
    second: Number,
    position: {
      type: String,
      default: 'right',
    },
  },
  emits: ['update:first', 'update:second'],
  data() {
    return {
      flagShowDiapazon: false,
      currFYear: 2024,
      currFMonth: '',
      currFDay: 1,
      currSYear: 2024,
      currSMonth: '',
      currSDay: 1,
      week: ['П', 'В', 'С', 'Ч', 'П', 'С', 'В'],
      weekLong: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      months: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],

      monthDaysF: [],
      monthDaysS: [],
      showBtnReady: false,

      year: 0,
      month: 0,
      weekDay: 0,

      monthDaysCnt: [
        31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
      ],
    };
  },
  computed: {
    dFirst() {
      return `${this.weekLong[this.currFWeek]}, ${this.currFDay} ${this.months[this.currFMonth]}. ${this.currFYear}`;
    },
    dSecond() {
      return `${this.weekLong[this.currSWeek]}, ${this.currSDay} ${this.months[this.currSMonth]}. ${this.currSYear}`;
    },
  },
  mounted() {
    const dateF = new Date(this.first * 1000);
    const dateS = new Date(this.second * 1000);

    this.currFYear = dateF.getFullYear();
    this.currFMonth = dateF.getMonth();
    this.currFDay = dateF.getDate();
    this.currFWeek = dateF.getDay() - 1;
    if (this.currFWeek === -1) {
      this.currFWeek = 6;
    }

    this.currSYear = dateS.getFullYear();
    this.currSMonth = dateS.getMonth();
    this.currSDay = dateS.getDate();
    this.currSWeek = dateS.getDay() - 1;
    if (this.currSWeek === -1) {
      this.currSWeek = 6;
    }

    this.renderMonthDays(this.currFWeek, this.currFMonth, this.currFDay, 0);
    this.renderMonthDays(this.currSWeek, this.currSMonth, this.currSDay, 1);
    this.rechangeLimitsColor();
  },
  methods: {
    toggleDiapazon() {
      this.flagShowDiapazon = !this.flagShowDiapazon;
    },
    nextMonth(type) {
      if (!this.checkLimitMonth(type, 0)) return;

      if (type === 0) {
        this.currFMonth += 1;
        if (this.currFMonth > 11) {
          this.currFMonth = 0;
          this.currFYear += 1;
        }

        const temp = new Date(this.currFYear, this.currFMonth, this.currFDay, 0, 0, 0)
          .getTime() / 1000;
        this.currFWeek = new Date(temp * 1000).getDay() - 1;
        if (this.currFWeek === -1) {
          this.currFWeek = 6;
        }

        this.renderMonthDays(this.currFWeek, this.currFMonth, this.currFDay, type);
        this.rechangeLimitsColor();
      } else {
        this.currSMonth += 1;
        if (this.currSMonth > 11) {
          this.currSMonth = 0;
          this.currSYear += 1;
        }

        const temp = new Date(this.currSYear, this.currSMonth, this.currSDay, 0, 0, 0)
          .getTime() / 1000;
        this.currSWeek = new Date(temp * 1000).getDay() - 1;
        if (this.currSWeek === -1) {
          this.currSWeek = 6;
        }

        this.renderMonthDays(this.currSWeek, this.currSMonth, this.currSDay, type);
        this.rechangeLimitsColor();
      }
    },
    prevMonth(type) {
      if (!this.checkLimitMonth(type, 1)) return;

      if (type === 0) {
        this.currFMonth -= 1;
        if (this.currFMonth < 0) {
          this.currFMonth = 11;
          this.currFYear -= 1;
        }

        const temp = new Date(this.currFYear, this.currFMonth, this.currFDay, 0, 0, 0)
          .getTime() / 1000;
        this.currFWeek = new Date(temp * 1000).getDay() - 1;
        if (this.currFWeek === -1) {
          this.currFWeek = 6;
        }

        this.renderMonthDays(this.currFWeek, this.currFMonth, this.currFDay, type);
        this.rechangeLimitsColor();
      } else {
        this.currSMonth -= 1;
        if (this.currSMonth < 0) {
          this.currSMonth = 11;
          this.currSYear -= 1;
        }

        const temp = new Date(this.currSYear, this.currSMonth, this.currSDay, 0, 0, 0)
          .getTime() / 1000;
        this.currSWeek = new Date(temp * 1000).getDay() - 1;
        if (this.currSWeek === -1) {
          this.currSWeek = 6;
        }

        this.renderMonthDays(this.currSWeek, this.currSMonth, this.currSDay, type);
        this.rechangeLimitsColor();
      }
    },
    checkLimitDay(newDay, type) {
      if (this.currFMonth > this.currSMonth
        && this.currFYear === this.currSYear) return false;
      if (this.currFYear > this.currSYear) return false;

      if (type === 0) {
        if (newDay > this.currSDay
          && this.currFMonth === this.currSMonth
          && this.currFYear === this.currSYear) return false;
      }

      if (type === 1) {
        if (newDay < this.currFDay
          && this.currFMonth === this.currSMonth
          && this.currFYear === this.currSYear) return false;
      }

      return true;
    },
    checkLimitMonth(type, nextOrPrevent) {
      // type означает left or right collumn
      let { currFMonth } = this;
      let { currSMonth } = this;
      let { currFYear } = this;
      let { currSYear } = this;

      if (nextOrPrevent === 0) {
        if (type === 0) {
          currFMonth += 1;

          if (currFMonth > 11) {
            currFMonth = 0;
            currFYear += 1;
          }
        }

        if (type === 1) {
          currSMonth += 1;

          if (currSMonth > 11) {
            currSMonth = 0;
            currSYear += 1;
          }
        }
      }

      if (nextOrPrevent === 1) {
        if (type === 0) {
          currFMonth -= 1;

          if (currFMonth < 0) {
            currFMonth = 11;
            currFYear -= 1;
          }
        }

        if (type === 1) {
          currSMonth -= 1;

          if (currSMonth < 0) {
            currSMonth = 11;
            currSYear -= 1;
          }
        }
      }

      if (currFMonth === currSMonth && currFYear === currSYear) {
        if (this.currFDay > this.currSDay && type === 0) {
          this.currFDay = this.currSDay;
        }
        if (this.currFDay > this.currSDay && type === 1) {
          this.currSDay = this.currFDay;
        }
      }

      if (currFMonth > currSMonth && currFYear === currSYear) return false;
      if (currFYear > currSYear) return false;

      return true;
    },
    rechangeLimitsColor() {
      if (this.currFMonth < this.currSMonth || this.currFYear < this.currSYear) {
        this.monthDaysF.forEach((item, index) => {
          item.forEach((el, ind) => {
            if (el.val === this.currFDay) {
              this.monthDaysF[index][ind].type = 1;
            } else if (el.val > this.currFDay) {
              this.monthDaysF[index][ind].type = 3;
            } else {
              this.monthDaysF[index][ind].type = 0;
            }
          });
        });
      }
      if (this.currFMonth === this.currSMonth && this.currFYear === this.currSYear) {
        this.monthDaysF.forEach((item, index) => {
          item.forEach((el, ind) => {
            if (el.val === this.currFDay) {
              this.monthDaysF[index][ind].type = 1;
            } else if (el.val > this.currFDay && el.val < this.currSDay) {
              this.monthDaysF[index][ind].type = 3;
            } else if (el.val === this.currSDay) {
              this.monthDaysF[index][ind].type = 4;
            } else if (el.val < this.currFDay) {
              this.monthDaysF[index][ind].type = 0;
            } else {
              this.monthDaysF[index][ind].type = 2;
            }
          });
        });
      }
      if (this.currFMonth < this.currSMonth || this.currFYear < this.currSYear) {
        this.monthDaysS.forEach((item, index) => {
          item.forEach((el, ind) => {
            if (el.val === this.currSDay) {
              this.monthDaysS[index][ind].type = 1;
            } else if (el.val > 0 && el.val < this.currSDay) {
              this.monthDaysS[index][ind].type = 3;
            } else {
              this.monthDaysS[index][ind].type = 0;
            }
          });
        });
      }
      if (this.currFMonth === this.currSMonth && this.currFYear === this.currSYear) {
        this.monthDaysS.forEach((item, index) => {
          item.forEach((el, ind) => {
            if (el.val === this.currSDay) {
              this.monthDaysS[index][ind].type = 1;
            } else if (el.val < this.currSDay && el.val > this.currFDay) {
              this.monthDaysS[index][ind].type = 3;
            } else if (el.val === this.currFDay) {
              this.monthDaysS[index][ind].type = 4;
            } else if (el.val > this.currFDay) {
              this.monthDaysS[index][ind].type = 0;
            } else {
              this.monthDaysS[index][ind].type = 2;
            }
          });
        });
      }
    },
    pickDay(newDay, week, type) {
      if (newDay <= 0) return;
      if (!this.checkLimitDay(newDay, type)) return;

      if (type === 0) {
        this.currFDay = newDay;
        this.currFWeek = week;
        this.rechangeLimitsColor();
        // this.monthDaysF.forEach((item, index) => {
        //   item.forEach((el, ind) => {
        //    if (el.val === newDay) {
        //      this.monthDaysF[index][ind].type = 1;
        //    } else {
        //      this.monthDaysF[index][ind].type = 0;
        //    }
        //  });
        // });
      } else {
        this.currSDay = newDay;
        this.currSWeek = week;
        this.rechangeLimitsColor();
        // this.monthDaysS.forEach((item, index) => {
        //  item.forEach((el, ind) => {
        //    if (el.val === newDay) {
        //      this.monthDaysS[index][ind].type = 1;
        //    } else {
        //      this.monthDaysS[index][ind].type = 0;
        //    }
        //  });
        // });
      }

      // const newTime = new Date(this.year, this.month, newDay, 0, 0, 0).getTime() / 1000;
      // this.$emit('update:modelValue', newTime);
      this.showBtnReady = true;
    },
    send() {
      const newTimeF = new Date(this.currFYear, this.currFMonth, this.currFDay, 0, 0, 0)
        .getTime() / 1000;
      const newTimeS = new Date(this.currSYear, this.currSMonth, this.currSDay, 0, 0, 0)
        .getTime() / 1000;

      this.$emit('update:first', newTimeF);
      this.$emit('update:second', newTimeS);

      this.flagShowDiapazon = false;
    },
    renderMonthDays(week, month, day, type) {
      const monthDays = [];
      const weekDays = 7;

      // определить первый день месяца
      let temp = week - (day % weekDays) + 1;
      if (temp < 0) temp += weekDays;

      if (type === 0) {
        if (this.currFYear % 4 === 0) {
          this.monthDaysCnt[1] = 29;
        } else { this.monthDaysCnt[1] = 28; }
      } else if (this.currSYear % 4 === 0) {
        this.monthDaysCnt[1] = 29;
      } else { this.monthDaysCnt[1] = 28; }

      let weekDaysCnt = 0;
      let tempWeek = [];
      for (let i = 1; i <= this.monthDaysCnt[month] + temp; i += 1) {
        const newEl = {
          val: i - temp,
          type: 0,
        };
        if (newEl.val === day) {
          newEl.type = 1;
        }

        tempWeek.push(newEl);

        weekDaysCnt += 1;
        if (weekDaysCnt === weekDays) {
          weekDaysCnt = 0;
          monthDays.push(tempWeek);
          tempWeek = [];
        }
      }
      if (tempWeek.length) {
        monthDays.push(tempWeek);
      }

      if (type === 0) {
        this.monthDaysF = monthDays;
      } else {
        this.monthDaysS = monthDays;
      }
    },
    closeSpoiler() {
      this.flagShowDiapazon = false;
    },
  },
};
</script>

<style scoped>
  .wb-input-diapazon{
    position: relative;
    display: inline-block;
  }
  .collapse-head{
    cursor: pointer;
  }
  .one-line{
    display: inline-block;
    vertical-align: top;

    font-size: 12px;
    padding: 4px 5px;
  }
  .grey{
    border: 1px solid #ddd;
    background-color: #f6f6f6;
  }
  .one-line i{margin-right: 4px;}
  .collapse-body{
    white-space: nowrap;
    position:absolute;
    top: 28px;
    z-index: 2;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 15px 15px 15px;
  }
  .collapse-body.right{right: 0px;}
  .collapse-body.left{left: 0px;}

  .one-calendar {
    /* width: 200px; */
  }
  .one-calendar .title > div {
    display: inline-block;
    vertical-align: top;
  }
  .one-calendar .title{
    text-align: center;
    height: 27px;
    line-height: 27px;
    font-weight: bold;
    position: relative;
  }
  .one-calendar:first-child{
    margin-right: 20px;
  }
  .title .arr{
    width: 25px;
    height: 25px;
    text-align: center;
    cursor: pointer;
    font-size: 22px;
  }
  .title .arr i{
    margin: 0px;
  }
  .title .arr.right{
    right: 0px;
    position: absolute;
    top: 0px;
  }
  .title .arr.left{
    left: 0px;
    position: absolute;
    top: 0px;
  }
  .week-days .week-day{
    display:inline-block;
    vertical-align: top;

    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border: 1px solid #fff;
  }
  .month-days .day{
    /*
    display: inline-block;
    vertical-align: top;
    */

    float: left;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    border: 1px solid #fff;
    font-size: 14px;
  }
  .day:hover{
    border-color: #ddd;
    background-color: #f6f6f6;
  }
  .day.active{
    background: #bcede8;
    border-color: #84afaa;
  }
  .day.between{
    background-color: #ddf9f7;
    border: 1px solid #bfe8e3;
  }
  .day.alien{
    background-color: #ddf9f7;
    border: 1px dashed #84afaa;
  }
  .day.no-click {
    color: #898989;
  }
  .btn-cross{
    border: none;
    border-radius: 2px;
    font-size: 12px;
    height: 28px;
    line-height: 28px;
    outline: 0;
    padding: 0 2rem;
    text-transform: uppercase;
    vertical-align: middle;
    text-decoration: none;
    color: #fff;
    background-color: #009688;
    text-align: center;
    letter-spacing: .5px;
    transition: 0.2s ease-out;
    cursor: pointer;
    position: relative;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    z-index: 1;
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    margin-bottom: 8px;
    margin-right: 5px;
    margin-top: 10px;
  }
  .btn-cross:hover {
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.18), 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    /* box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);*/
  }
</style>
