<template>
  <transition name="fade">
    <Loader v-if="spiner" />
    <div v-else>
      <div class="title" v-if="title">{{title}}</div>
      <div ref="body" class="order-body" @click="closeCollpase"
        @keydown.f7="closeCollpase">
        <div class="stock-area" v-for="(el, ind) in list" :key="ind">
          <button v-if="readonly == 0" class="btn-order-ext" @click="addProduct(el)">
            <i class="fa fa-plus" aria-hidden="true"></i>
            <span>Добавить товар</span>
          </button>
          <div class="name"><i class="fa fa-cubes" aria-hidden="true"></i> {{el.name}}</div>
          <div v-if="el.ids" class="sub-els">
            <div v-for="(el2, ind2) in el.ids" :key="ind2" class="sub-el">
              <div class="sub-title">
                <div class="sub-info-ext">
                  <span class="sub-status">
                    <template v-if="el2.send === 0">В корзине</template>
                    <template v-if="el2.send === 1">В пути</template>
                    <template v-if="el2.send === 2">В архиве</template>
                  </span>
                  <span v-if="el2.send === 2"
                    style="margin-left: 4px;">{{el2.cnt_real}} / {{el2.cnt_need}}</span>
                </div>
                <div><i class="fa fa-truck" aria-hidden="true"></i> {{el2.suname}}</div>
              </div>
              <div class="g-elements">
                <div class="sub-sub-el"
                  v-for="(el3, ind3) in el2.prods" :key="ind3">
                  <button @click.stop="showBurgerContext(ind, ind2, ind3, $event)" class="burger">
                    <i class="fa fa-bars"></i>
                  </button>
                  <div style="float:right" v-if="readonly == 0">
                    <div
                      class="prod-ext-nifo">Остаток:{{el3.cnt}}</div>
                    <div
                      class="prod-ext-nifo">ЦУ: {{el3.cu}}</div>
                    <div
                      class="prod-ext-nifo">В пути: {{el3.cnt_way}}</div>
                    <div
                      class="prod-ext-nifo">Продажи за 2Tпоп: {{el3.last_sales}}</div>
                    <input v-model="el3.unit" class="inp" @input="chgOrder" />
                  </div>
                  <div v-else style="float:right">
                    <div class="cnt-readonly">{{el3.unit}}</div>
                  </div>
                  <span style="vertical-align: middle">{{el3.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :style="{top: topContrxtMenu + 'px', left: leftContrxtMenu + 'px'}"
          class="context-menu-ord"
          :class="{show: showContextMenu}">
          <div class="context-menu-ord-item" @click="menuitem(0)" @keydown.f9="menuitem(0)">
            <i class="fa fa-line-chart" aria-hidden="true"></i>
            <span>График</span>
          </div>
          <div v-if="readonly == 0" class="context-menu-ord-item"
          @click="menuitem(1)" @keydown.f9="menuitem(1)">
            <i class="fa fa-truck" aria-hidden="true"></i>
            <span>Сменить поставщика</span>
          </div>
          <div v-if="readonly == 0" class="context-menu-ord-item"
          @click="menuitem(3)" @keydown.f9="menuitem(3)">
            <i class="fa fa-exchange" aria-hidden="true"></i>
            <span>Разбить на несколько поставщиков</span>
          </div>
          <div v-if="readonly == 0" class="context-menu-ord-item"
          @click="menuitem(2)" @keydown.f9="menuitem(2)">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
            <span>Удалить из корзины</span>
          </div>
        </div>
      </div>
      <a v-if="flagShowBtnOrder" @click="submit"
        @keydown.f9="submit" class="btn-cross fix-right-bottom">Применить изменения</a>
      <transition name="fade">
        <div v-if="message.length > 0" class="answer"
          :class="{danger: !flagSuccess}">{{message}}</div>
      </transition>
    </div>
  </transition>
  <transition name="fade">
    <ModalRight v-show="flagModalRight"
      @close="flagModalRight = false;" :class="modalClass">
      <component :is="modalComponent" v-bind="modalConf"
        :title="modalTitle"
        v-if="flagModalRight" @answer="res"></component>
    </ModalRight>
  </transition>
</template>

<script>
import axios from 'axios';

import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import ConstructorDef1 from '@/components/v2/cross/ii/ConstructorDef.vue';
import Form from '@/components/v2/cross/form/Form.vue';
import OrderSuborder from '@/components/v2/cross/unique/OrderSuborder.vue';
import OrderSplit from '@/components/v2/cross/unique/OrderSplit.vue';
import Loader from '@/components/v2/cross/loader/Loader.vue';

export default {
  name: 'ManageOrder',
  components: {
    ModalRight,
    ConstructorDef1,
    Form,
    OrderSuborder,
    OrderSplit,
    Loader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    readonly: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      list: [],
      flagShowBtnOrder: false,
      flagSuccess: 0,
      message: '',

      newData: [],
      newOrders: [],
      selected: {},
      currPackOrder: 0,

      showContextMenu: false,
      topContrxtMenu: 0,
      leftContextMenu: 0,

      flagModalRight: false,
      modalComponent: '',
      modalConf: {},
      modalTitle: '',
      modalClass: 'w100',

      errors: [],
      spiner: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.action) return;
      axios.get(this.action)
        .then((res) => {
          // console.log('orig', res.data.list);
          this.list = res.data.list;
          this.currPackOrder = res.data.pack_order;
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    closeCollpase() {
      this.showContextMenu = false;
    },
    showBurgerContext(ind, ind2, ind3, ev) {
      const st = this.list[ind].id;
      const ord = this.list[ind].ids[ind2].id;
      const su = this.list[ind].ids[ind2].supp_id;
      // const pr = this.list[ind].ids[ind2].prods[ind3].product_id;
      const pr = this.list[ind].ids[ind2].prods[ind3];
      this.selected = {
        st,
        ord,
        su,
        pr,
        ind,
        ind2,
        ind3,
      };

      this.showContextMenu = true;

      this.topContrxtMenu = ev.pageY - 50 + this.$refs.body.scrollTop;
      this.leftContrxtMenu = ev.pageX - (window.innerWidth / 100) * 5;

      const rect = ev.target.getBoundingClientRect();
      console.log('parent', rect.top, rect.left);
    },
    menuitem(action) {
      if (action === 0) { // отобразить график
        this.flagModalRight = true;
        this.modalClass = 'w100';
        this.modalTitle = `График «${this.selected.pr.name}»`;

        this.modalComponent = 'ConstructorDef1';
        this.modalConf = {
          title: `${this.selected.pr.name}`,
          pack: [
            {
              component: 'LabelsDef',
              conf: {
                action: `/v2/product/item-all?stock=${this.selected.st}&prod=${this.selected.pr.product_id}`,
              },
            }, {
              component: 'ChartLiner',
              conf: {
                action: `/v2/report/sku-graph?stock=${this.selected.st}&prod=${this.selected.pr.product_id}`,
              },
            },
          ],
        };
      } else if (action === 1) { // сменить поставщика
        this.flagModalRight = true;
        this.modalClass = 'w50';
        this.modalTitle = `Смена поставщика у «${this.selected.pr.name}»`;

        this.modalComponent = 'Form';
        this.modalConf = {
          // title: `Смена поставщика у «${this.selected.pr.name}»`,
          init_action: `/v2/order/get-form-chg-supp?stock=${this.selected.st}&prod=${this.selected.pr.product_id}`,
          btn_action: `/v2/order/save-form-chg-supp?stock=${this.selected.st}&prod=${this.selected.pr.product_id}&supp=${this.selected.su}&ord=${this.selected.ord}&order_pack=${this.currPackOrder}`,
          btn_lbl: 'Применить',
        };
      } else if (action === 2) { // удалить товар из заказа
        this.list[this.selected.ind].ids[this.selected.ind2].prods.splice(this.selected.ind3, 1);
        if (!this.list[this.selected.ind].ids[this.selected.ind2].prods.length) {
          this.list[this.selected.ind].ids.splice(this.selected.ind2, 1);
          if (!this.list[this.selected.ind].ids.length) {
            this.list.splice(this.selected.ind, 1);
          }
        }

        axios.post('/v2/order/del-product', {
          order: this.selected.ord,
          prod: this.selected.pr.product_id,
        })
          .then(() => {
          })
          .catch((e) => {
            this.errors.push(e);
          });
      } else if (action === 3) { // разбить заказ
        this.flagModalRight = true;
        this.modalClass = 'w100';
        this.modalTitle = `Разбить заказ для «${this.selected.pr.name}»`;

        this.modalComponent = 'OrderSplit';
        this.modalConf = {
          pack_id: this.currPackOrder,
          id: this.selected.st,
          name: this.list[this.selected.ind].name,
          prod_id: this.selected.pr.product_id,
          prod_name: this.selected.pr.name,
        };
        console.log(this.modalConf);
      }
    },
    chgOrder() {
      this.flagShowBtnOrder = true;
      this.message = '';
    },
    submit() {
      this.spiner = true;
      this.newData = [];

      this.list.forEach((el) => {
        el.ids.forEach((el2) => {
          el2.prods.forEach((el3) => {
            this.newData.push({
              val1: el2.id,
              val2: el3.product_id,
              val3: el3.unit,
            });
          });
        });
      });

      axios.post('/v2/order/save-chg', { data: this.newData })
        .then((res) => {
          this.flagSuccess = res.data.success;
          this.message = res.data.answer;
          this.flagShowBtnOrder = false;

          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    addProduct(el) {
      this.flagModalRight = true;
      this.modalTitle = `Добавить товар для «${el.name}»`;
      this.modalComponent = 'OrderSuborder';
      this.modalClass = 'w100';
      this.modalConf = {
        pack_id: this.currPackOrder,
        id: el.id,
        name: el.name,
      };
    },
    res(data) {
      if (data.t === 'all') {
        this.list = data.elem;
      }
    },
  },
};
</script>

<style scoped>
.order-body{
  overflow-y: auto;
  height: calc(100vh - 112px);
  margin-top: 12px;
  padding: 0px 6px 54px;
  position: relative;
}
.btn-order-ext{
  color: #000;
  font-size: 14px;
  line-height: 24px;
  z-index: 1;
  position: relative;
  padding: 1px 8px;
  border: none;
  background-color: #eeeeee;
  float: right;
  /* height: 30px; */

  cursor: pointer;
  margin-top: 2px;
  margin-right: 7px;
  transition: background-color 0.8s, box-shadow 0.8s;
}
.btn-order-ext i{
  margin-right: 4px;
}
.btn-order-ext span{
}
.btn-order-ext:hover{
  background-color: #fff;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.prod-ext-nifo{
  display: inline-block;
  vertical-align: top;

  margin-right: 5px;
  border: 1px solid #ddd;
  background-color: #f6f6f6;

  font-size: 12px;
  padding: 2px 5px;
  margin-bottom: 0px;
}
.context-menu-ord{
  position: absolute;
  display: none;
}
.context-menu-ord.show{
  display: block;
  background-color: #fff;
  padding: 8px 0px;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.context-menu-ord-item{padding: 6px 8px; cursor: pointer;}
.context-menu-ord-item:hover{
  background-color: #ddd;
}
.context-menu-ord-item i{
  width: 18px;
  margin-right: 8px;
  text-align: right;
}
.title{
  color: #1c2428;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border: none;
  padding: 10px 12px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.sub-info-ext{
  float: right;
}
.stock-area{
  box-shadow: 0px 1px 8px #aaa;
}
.name{
  padding: 6px 11px;
  background-color: #ccc;

  position: sticky;
  top: 0px;
}
.sub-els{
  background-color: #e3e3e3;
  padding: 6px 7px 4px;
}
.sub-els .sub-el{
  background: #eee;
  margin-bottom: 8px;
  box-shadow: 0px 1px 8px #aaa;
}
.sub-title{
  background-color: #f2f2f2;
  padding: 4px;
  /* box-shadow: 0px 1px 8px #aaa; */
}
.g-elements{
}
.sub-sub-el{
  padding: 4px 6px;
}
.sub-sub-el:hover{
  transition: background-color 0.3s, box-shadow 0.3s;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.burger{
  border: none;
  color: #21458d;
  width: 17px;
  padding: 0px;
  margin-right: 5px;
  cursor: pointer;
  background-color: transparent;
}
.inp{
  width: 65px;
  border: 1px solid #b3b3b3;
  border-radius: unset;
  padding: 2px;
  outline: none;
}
.inp:focus-visible, .inp:focus{
  border-radius: unset;
  border: 1px solid #000;
}

.btn-cross{
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  outline: 0;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;

  text-decoration: none;
  color: #fff;
  background-color: #009688;
  text-align: center;
  letter-spacing: .5px;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  cursor: pointer;

  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.btn-cross:hover{
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.btn-cross.fix-right-bottom{
  position: fixed;
  right: 10px;
  bottom: 10px;
}
.answer{
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #bbf7cb;
  border: 1px solid #8fbd9c;
  padding: 13px 46px;
}
.answer.danger {
  background: #fddbdb;
  border: 1px solid #9d8989;
}

</style>
