<template>
  <div class="wrp-body">
    <h1 class="head"><span>ЛК управления клиентами</span></h1>
    <MenuTab
      :els="tabs"
      @activeTab="selTab"
    >
      <component :is="currentTabComponent" v-bind="currentOptions" :key="key"></component>
    </MenuTab>
  </div>
</template>

<script>
import axios from 'axios';
import MenuTab from '@/components/v2/cross/menu/MenuTab.vue';
import ListManage from '@/components/v2/cross/list/ListManage.vue';
// import Services from '@/components/pages/Services.vue';

export default {
  name: 'IfaceLrManager',
  components: {
    MenuTab, ListManage,
  },
  data() {
    return {
      filterSet: '',
      key: 0,

      currentTab: '',
      currOptions: {},
      tabs: [],
    };
  },
  mounted() {
    this.setTabs();
  },
  computed: {
    currentTabComponent() {
      return `${this.currentTab}`;
    },
    currentOptions() {
      return this.currOptions;
    },
  },
  methods: {
    selTab(tab) {
      this.currentTab = tab.name;
      this.currOptions = tab.options;
      this.key += 1;
    },
    setTabs() {
      axios.get('/v2/lk-manager/for-tabs')
        .then((res) => {
          if (res.data.success === 1) {
            this.tabs = res.data.tabs;
            this.currentTab = res.data.component.name;
            this.currOptions = res.data.component.options;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
h1.head{color: #000; border: none; background-color: #fff;}
.wrp-body{
  background: #345f9012;
  height: calc(100vh - 50px);
  overflow: hidden;
  overflow-y: auto;
}
h1{
  color: #1c2428;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border: none;
  padding-top: 5px;
  padding-bottom: 10px;
  margin: 0px;
}
h1 span{
  background: transparent;
  padding: 0px 12px;
}
</style>
