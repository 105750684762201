<template>
  <transition name="fade">
    <Loader v-if="spiner" />
    <div v-else>
      <div class="title" v-if="title">{{title}}</div>
      <div class="body-context">
        <div class="field">
          <div class="field-title">Пункт доставки</div>
          <div class="field-line">{{name}}</div>
        </div>
        <div class="field">
          <div class="field-title">Заказываемый товар</div>
          <div class="field-line">{{prod_name}}</div>
        </div>
        <div class="field" v-if="suppliers.length">
          <table class="table">
            <thead>
            <tr>
              <th>Поставщик</th>
              <th>Время пополнения</th>
              <th>Минимальная партия</th>
              <th>Основной</th>
              <th>В корзину</th>
            </tr>
            </thead>
            <tbody>
            <tr class="el" v-for="(el, ind) in suppliers" :key="ind">
              <td>{{el.name}}</td>
              <td>{{el.refill_time}}</td>
              <td>{{el.min_party}}</td>
              <td>{{el.main}}</td>
              <td><input v-model="el.val" /></td>
            </tr>
            </tbody>
          </table>
        </div>
        <button v-if="showSubmit"
          class="btn-cross fix-right-bottom" @click="send">{{btn.title}}</button>
        <transition name="fade">
          <div v-if="message.length > 0" class="form-answer"
            :class="{danger: !flagSuccess}">{{this.message}}</div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';

import Loader from '@/components/v2/cross/loader/Loader.vue';

export default {
  name: 'OrderSplit',
  components: {
    Loader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    pack_id: {
      type: Number,
      default: 0,
    },
    id: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    prod_id: {
      type: Number,
      default: 0,
    },
    prod_name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      action: '/v2/order/form-suborder',
      btn: {
        title: '',
        action: '',
      },
      flagShowBtn: true,
      flagSuccess: 0,
      message: '',

      suppliers: [],

      errors: [],
      spiner: true,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    showSubmit() {
      let res = true;
      if (this.message.length > 0) {
        res = false;
      }
      return res;
    },
  },
  methods: {
    init() {
      axios.get(`/v2/order/get-suppliers?stock=${this.id}&prod=${this.prod_id}&pack=${this.pack_id}`)
        .then((res) => {
          this.suppliers = res.data.list;
          this.spiner = false;
          this.btn = res.data.btn;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    selectEl(ind) {
      this.selectedProd = this.selectedProds[ind];
      this.search = '';
      this.selectedProds = [];
      this.selectedShow = true;
    },
    send() {
      if (!this.btn.action) return;

      const data = {
        order_pack: this.pack_id,
        stock: this.id,
        prod: this.prod_id,
        supps: this.suppliers,
      };

      axios.post(this.btn.action, data)
        .then((res) => {
          this.flagSuccess = res.data.success;
          this.message = res.data.answer;
          // this.selectedProd.supps = [];
          if (res.data.success === 1) {
            this.$emit('answer', res.data.res);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    showSel() {
      if (this.selectedProd.id) {
        this.selectedShow = true;
      } else {
        this.selectedShow = false;
      }
    },
    showInput() {
      this.selectedShow = false;
      this.$nextTick(() => {
        document.getElementById('fprod').focus();
      });
    },
  },
};
</script>

<style scoped>
.progress.progress-striped{
  margin-bottom: 20px !important;
  width: 50%;
}
.body-context{
  padding-left: 14px;
  height: calc(100vh - 86px);
  position: relative;
  overflow-y: auto;
  padding: 20px 12px;
}
.btn-cross{
  border: none;
  border-radius: 2px;
  display: inline-block;
  font-size: 17px;
  height: 36px;
  line-height: 36px;
  outline: 0;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: #fff;
  background-color: #009688;
  text-align: center;
  letter-spacing: .5px;
  transition: 0.2s ease-out;
  cursor: pointer;
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: all 0.3s ease-out;

  position: fixed;
  right: 10px;
  bottom: 10px;
}
.btn-cross:hover{
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
table.table, table.info {
    border-spacing: 0px;
    border-collapse: collapse;
}
.table, table.info {
  border-collapse: collapse;
  margin-bottom: 20px;
}
.table {
  background: #fff;
  width: 99%;
  margin-bottom: 50px;
}

table.info{width: 50%;}
table.info td{
  padding: 7px;
  border-bottom: 1px solid #dadee0;
}
table.info th{
  font-weight: normal;
  text-align: left;
  background: #f6f8fa;
  white-space: nowrap;
  padding: 7px;
  border-bottom: 1px solid #dadee0;
}

.table thead th{
  font-weight: 100;
  text-align: left;
  background: #f6f8fa;
  font-size: 11px;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  vertical-align: top;

  border-right: 1px solid #dadee0;
  border-top: 1px solid #dadee0;
  padding: 7px 7px;
  height: 13px;
  border-bottom: 1px solid #6dc2ed;
}

.table tbody td{
  text-align: left;
  border-bottom: 1px solid #dadee0;
  padding: 7px 7px;
  font-size: 13px;
  white-space: nowrap;
}

.stock{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 13px;
}

input{
  display: inline-block;
  width: 100%;
  min-width: 200px;
  /* padding: 4px 6px; */
  /* border-radius: 0 4px 4px 0px; */
  /* border: 1px solid #aaa; */
  /* border-left: 4px solid #aaa; */
  font-size: 12px;
  line-height: 18px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #dadee0;
  transition: all 0.3s;
  padding-left: 3px;
}
input:focus{
  border-color: #999;
}

.form-answer{
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #bbf7cb;
  border: 1px solid #8fbd9c;
  padding: 13px 46px;
}
.form-answer.danger {
  background: #fddbdb;
  border: 1px solid #9d8989;
}

.title {
  color: #1c2428;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border: none;
  padding: 10px 12px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.field{
  margin-bottom: 24px;
}
.field-title{
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 14px;
}
.field-body{
  position: relative;
}
.selected-products{
  position: absolute;
  top: 37px;
  left: 0px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  height: 405px;
  overflow-y: auto;
}
.selected-products > div{
  padding: 6px;
  cursor: pointer;
}
.selected-products > div:hover{
  background-color: #ddd;
}

table.table, table.info {
    border-spacing: 0px;
    border-collapse: collapse;
}
.table, table.info {
  border-collapse: collapse;
  margin-bottom: 20px;
}
.table {
  background: #fff;
  width: 99%;
  margin-bottom: 50px;
}

table.info{width: 50%;}
table.info td{
  padding: 7px;
  border-bottom: 1px solid #dadee0;
}
table.info th{
  font-weight: normal;
  text-align: left;
  background: #f6f8fa;
  white-space: nowrap;
  padding: 7px;
  border-bottom: 1px solid #dadee0;
}

.table thead th{
  font-weight: 100;
  text-align: left;
  background: #f6f8fa;
  font-size: 11px;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  vertical-align: top;

  border-right: 1px solid #dadee0;
  border-top: 1px solid #dadee0;
  padding: 7px 7px;
  height: 13px;
  border-bottom: 1px solid #6dc2ed;
}

.table tbody td{
  text-align: left;
  border-bottom: 1px solid #dadee0;
  padding: 7px 7px;
  font-size: 13px;
  white-space: nowrap;
}
input{
height: 33px;
width: 50%;
font-size: 16px;
line-height: 33px;
font-weight: normal;
padding-left: 8px;
}

.selected-line{
  display: inline-block;
  width: 100%;
  min-width: 200px;
  /* padding: 4px 6px; */
  /* border-radius: 0 4px 4px 0px; */
  /* border: 1px solid #aaa; */
  /* border-left: 4px solid #aaa; */
  font-size: 12px;
  line-height: 18px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #dadee0;
  transition: all 0.3s;
  padding-left: 3px;

  height: 33px;
  width: 50%;
  font-size: 16px;
  line-height: 33px;
  font-weight: normal;
  padding-left: 8px;
  background-color: #fff;
  cursor: text;
}
.empty-text{
  /* display: inline-block; */
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  width: calc(50% - 1px);
  height: 33px;
  padding: 8px;
  box-sizing: border-box;
}
</style>
