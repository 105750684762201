<template>
  <Loader v-if="loaderContent" />
  <div class="wrapper-content" v-else>
    <h1 class="v2" :class="{'shadow': showFilterDepend === false || 1}">
      <span>{{mainTitle}}</span>
      <div class="btn-tab2__toggle">
        <CheckboxToggle v-show="modeMain !== 3"
          :text="textToggle"
          v-model="switchModeMain"
          @change="changeMode(switchModeMain)" />
      </div>
      <div class="btn-tab2">
        <SelectCustom v-show="modeMain !== 3"
          v-model="mode.value"
          class="small-size"
          :lbl="mode.lbl"
          :options="mode.options"
          :def="mode.lbl"
          :active="showTypeControl"
          @change="clearCatsOperations"
        />
      </div>
    </h1>
    <transition name="change-height">
      <div class="filter-depend" v-if="showFilterDepend && 0">
        <template v-for="(el, ind) in filter" :key="ind">
          <SelectLoad v-if="el.type == 'depend'"
            v-model="el.value"
            class="small-size"
            :def="el.placeholder"
            :lbl="el.lbl"
            :action="el.action"
            :params="{
              stock: stock,
              mode: mode.value,
            }"
            :ind="ind"
            @change="setFilterFields(ind)"
          />
          <br v-if="el.type == 'separated'" />
        </template>
      </div>
    </transition>
    <div class="wrapper-blocks" :class="{'showedFilter': showFilterDepend && 0}">
      <div class="block1"
        :class="{active: modeMain === 2 || modeMain === 3}"
        :style="{width: widthBlock1 + widthExtention}">
        <CategoryFunctional
          :stock="gettersAuthData.stocks.value.val"
          :needCheckbox="showCheckbox"
          :mode="mode.value"
          :filter="filter"
          :key="updateCategory"
          :action="categoryAction"
          :modeMain="modeMain"
          :oldModeMain="oldModeMain"
          @transferCats="saveData"
          @transferProds="selProdIds"
          @changeShow="changeShow"
          @clearCatsProds="clearCatsOperations"
        />
      </div>
      <div :style="{width: widthBlock2}"
        class='wrapper-second-window' >
        <template v-if="ResizableBlock">
          <ResizableBlock
            :blockWidth="widthBlock1"
            :maxWidth="maxWidth"
            :minWidth="minWidth"
            @changeWidth="changeWidthBlock1"
          />
        </template>
        <div class="block2" v-if="modeMain === 1 && keyCats !== ''">
          <ListManage class="stock-set"
            :action="actionProduct"
            :outerFilter="outerFilter"
            :key="keyCats"
            :fieldCheckbox="true"
          />
          <!-- @gotidsProdsOperations="transferToOperations"
          @showParentComponent="showOperations" -->
        </div>
        <div class='block3-2'
          v-show="(activedCatsOperations && modeMain === 2)">
          <Operations class="mode2"
            :field="operations"
            :stock="stock"
            :cats="idsCatsOperations"
            :prods="idsProdsOperations"
            :action="actionProductOperation"
            :modeMain="modeMain"
            @outError="outError"
          />
        </div>
      </div>
      <!-- <transition name="slide-form">
        <ModalRight v-show="flagModalRight && activedProdsOperations"
          @close="flagModalRight = false;"
          :title="modalTitle">
          <div class="block3">
            <Operations
              :field="operations"
              :stock="stock"
              :cats="idsCatsOperations"
              :prods="idsProdsOperations"
              :action="actionProductOperation"
              :modeMain="modeMain"
              @outError="outError"
              @updProducts="updProdNoSett"
            />
          </div>
        </ModalRight>
      </transition> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import SelectLoad from '@/components/v2/cross/select/SelectLoad.vue';
import SelectCustom from '@/components/v2/serj/Select.vue';
// import ConfirmCrossDef from '@/components/v2/cross/confirm/Def.vue';
// import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import ListManage from '@/components/v2/cross/list/ListManage.vue';
import CategoryFunctional from '@/components/v2/serj/CategoryFunctional.vue';
import ResizableBlock from '@/components/v2/serj/ResizableBlock.vue';
import Loader from '@/components/loader/LoaderTreeSpin.vue';
import Operations from '@/components/v2/serj/OperationsManage.vue';
import CheckboxToggle from '@/components/v2/serj/CheckboxToggle.vue';

export default {
  name: 'IfaceStockSet',
  components: {
    SelectLoad,
    CategoryFunctional,
    ResizableBlock,
    Operations,
    // ConfirmCrossDef,
    ListManage,
    // ModalRight,
    SelectCustom,
    Loader,
    CheckboxToggle,
  },
  data() {
    return {
      textToggle: ['по товарам', 'по категориям'],

      // headerName: '',
      initialMouseX: 0,
      maxWidth: 700,
      minWidth: 50,
      widthExtention: 'px',
      widthBlock1: 300,
      widthBlock2: 'calc(100% - 300px)',
      oldModeMain: 0,

      idsProdsOperations: [],
      idsCatsOperations: [],
      operations: [],
      actionOperations: '',
      switchModeMain: 0,
      modeMain: 1,
      actionCategoryPost: '/v2/category/list-by-stock-prod',
      actionCategoryGet: '/v2/category/list-by-stock-sett',
      actionProduct: '/v2/product/list-by-categories',
      actionCategoryOperation: '/v2/category/list-by-stock-sett-save',
      actionProductOperation: '/v2/product/multi-set',
      outerFilter: {},
      stock: 0,

      keyCats: '',
      cats: [],
      loaderContent: true,
      activedblock3: 0,
      flagModalRight: false,
      fieldParams:
      {
        button: 'button-td',
        icon: 'fa-bars',
        list: [
          {
            icon: 'fa-cogs',
            title: 'Настроить',
            type: 'GL',
            action: '/scm/product/upd/9729317',
            params: {
              id: 0,
            },
          },
        ],
      },
      filter: [],
      mode: {},
      extFields:
      {
        stock: {
          val: 1017,
          lbl: 'Склад 2',
          flag_main: 0,
          flag: 0,
          type: 0,
        },
        controll: 0,
      },
      tabs: [],
      errors: [],
    };
  },
  computed: {
    ...mapGetters('auth', {
      gettersAuthData: 'getAuthData',
    }),
    updateCategory() {
      if (this.switchModeMain === 0 && this.idsCatsOperations.length > 0) {
        return this.mode.value.val + JSON.stringify(this.filter) + this.categoryAction;
      }

      return this.mode.value.val + JSON.stringify(this.filter) + this.categoryAction;
    },
    showTypeControl() {
      if (this.modeMain === 1) {
        return true;
      }

      if (this.modeMain === 2) {
        return true;
      }

      if (this.modeMain === 3) {
        return false;
      }

      return false;
    },
    showFilterDepend() {
      if (this.modeMain === 1 && this.mode.value.val !== 0) {
        return true;
      }

      if (this.modeMain === 2 && this.mode.value.val !== 0) {
        return true;
      }

      if (this.modeMain === 3) {
        return false;
      }

      return false;
      // return this.mode.value.filter;
    },
    showCheckbox() {
      if (this.modeMain === 1) {
        return false;
      }

      if (this.modeMain === 2) {
        return false;
      }

      if (this.modeMain === 3) {
        return true;
      }

      return false;
    },
    mainTitle() {
      if (this.modeMain === 1) {
        return 'Массовая настройка свойств';
      }

      if (this.modeMain === 2) {
        return 'Массовая настройка свойств';
      }

      if (this.modeMain === 3) {
        return 'Подключение категорий к активному складу';
      }

      return '';
    },
    ResizableBlock() {
      if (this.modeMain === 1) {
        return true;
      }

      if (this.modeMain === 2) {
        return true;
      }

      if (this.modeMain === 3) {
        return false;
      }

      return false;
    },
    // activedProdsOperations() {
    //   return this.idsProdsOperations.length > 0;
    // },
    activedCatsOperations() {
      return this.idsCatsOperations.length > 0;
    },
    // activedCats() {
    //   return this.cats.length > 0;
    // },
    categoryAction() {
      if (this.modeMain === 3) {
        return this.actionCategoryGet;
      }

      return this.actionCategoryPost;
    },
    // actionOperation() {
    //   if (this.modeMain === 1) {
    //     return this.actionProductOperation;
    //   }
    //   if (this.modeMain === 2) {
    //     return this.actionCategoryOperation;
    //   }

    //   return '';
    // },
  },
  created() {
    this.stock = this.gettersAuthData.stocks.value.val;
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loaderContent = true;
      axios.get('/v2/iface/setting-multi', { params: { stock: this.stock } })
        .then((res) => {
          if (res.data.success === 1) {
            // this.headerName = res.data.name;
            this.mode = res.data.mode;
            this.filter = res.data.filter;
            this.loaderContent = false;
            this.operations = res.data.operations;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    outError() {
      this.operations.forEach((el) => {
        if (el.fields.length > 0) {
          if (el.fields[0].type === 'I') {
            el.fields[0].error = '';
          }
          if (el.fields[0].type === 'Z') {
            if (Object.prototype.hasOwnProperty.call(el.fields[0].value, 'fields')) {
              el.fields[0].value.fields[0].error = '';
            }
          }
          if (el.fields[0].type === 'MX') {
            if (Object.prototype.hasOwnProperty.call(el.fields[0].value, 'fields')) {
              el.fields[0].value.fields[0].error = '';
            }
            el.fields[0].options.forEach((el2) => {
              if (el2.type === 'I') {
                el2.error = '';
              }
            });
          }
        }
      });
    },
    changeWidthBlock1(width) {
      this.widthBlock1 = width;
      this.widthExtention = 'px';
      this.widthBlock2 = `calc(100% - ${width}px)`;
    },
    clearCatsOperations() {
      // this.idsProdsOperations = [];
      this.idsCatsOperations = [];
      this.keyCats = '';
    },
    // transferToOperations(ids) {
    //   this.idsProdsOperations = ids;
    // },
    // showOperations(el) {
    //   if (el.component === 'Operations') {
    //     if (this.idsProdsOperations?.length) {
    //       this.flagModalRight = true;
    //     } else {
    //       alert('Выбери товары для применения операции');
    //     }
    //   }
    // },
    changeMode(boolean) {
      // this.idsProdsOperations = [];
      // this.widthBlock1 = 100;
      // this.widthExtention = '%';
      if (boolean) {
        this.modeMain = 2;
        this.widthBlock1 = 500;
        this.maxWidth = 970;
        this.widthExtention = 'px';
        this.widthBlock2 = `calc(100% - ${this.widthBlock1}px)`;
      } else {
        this.modeMain = 1;
        this.widthBlock1 = 300;
        this.maxWidth = 750;
        this.widthExtention = 'px';
        this.widthBlock2 = `calc(100% - ${this.widthBlock1}px)`;
      }
    },
    changeShow(mode) {
      this.oldModeMain = this.modeMain;
      this.modeMain = mode;

      if (this.modeMain === 3) {
        this.widthBlock1 = 100;
        this.widthExtention = '%';
        this.widthBlock2 = 'auto';
      }
      if (this.modeMain === 2) {
        this.modeMain = 2;
        this.widthBlock1 = 500;
        this.maxWidth = 970;
        this.widthExtention = 'px';
        this.widthBlock2 = `calc(100% - ${this.widthBlock1}px)`;
      }
      if (this.modeMain === 1) {
        this.modeMain = 1;
        this.widthBlock1 = 300;
        this.maxWidth = 750;
        this.widthExtention = 'px';
        this.widthBlock2 = `calc(100% - ${this.widthBlock1}px)`;
      }
    },
    saveData(cats) {
      // this.idsProdsOperations = [];
      this.idsCatsOperations = cats;
      this.keyCats = cats.join('');

      this.outerFilter = {
        stock: this.gettersAuthData.stocks.value.val,
        cats,
        extFilter: [],
        mode: this.mode.value.val,
        operations: this.operations,
      };
    },
    selProdIds(ids) {
      // this.idsProdsOperations = [];
      this.keyCats = ids.join('');

      this.outerFilter = {
        stock: this.gettersAuthData.stocks.value.val,
        prods: ids,
        extFilter: [],
        mode: this.mode.value.val,
      };
    },
    selTab(ind) {
      this.loader = true;
      let len = this.tabs.length;
      while (len) {
        len -= 1;
        if (len === ind) {
          this.tabs[len].show = true;
        } else {
          this.tabs[len].show = false;
        }
      }
      this.loader = false;
    },
    updProdNoSett() {
      // el.operation.link_supp_prod
      // el.operation.type_chg_cu
      // el.operation.mode и если val не равен 0, если равен то значёк поставить
      // console.log('тип операции', el);
      // console.log('выбранные товары', this.idsProdsOperations);
    },

    setFilterFields(ind) {
      console.log(this.filter[ind].name);
    },
  },
};
</script>

<style scoped>
.wrapper-content {
  overflow: hidden;
}
.change-height-enter-from {
  max-height: 0px;
  overflow: hidden;
}
.change-height-enter-active {
  transition: max-height ease-out 0.3s;
}
.change-height-enter-to {
  max-height: 200px;
  overflow: auto;
}
.change-height-leave-from {
  max-height: 200px;
  overflow: auto;
}
.change-height-leave-active {
  transition: max-height ease-out 0.3s;
}
.change-height-leave-to {
  max-height: 0px;
  overflow: hidden;
}
.wrapper-second-window {
  /* display:flex;  */
  overflow: auto;
  position: relative;
  width: auto;
  /* transition: width 0.3s; */
}
h1.v2{
  background-color: white;
  border: 0px;
  padding-bottom: 5px;
  margin-bottom: 0px;
}
h1.v2.shadow {
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 5px 0px;
  transition: all 0.3s;
  transition-delay: 0.3s;
}
h1.v2 span{
  background: transparent;
  padding: 0px 12px;
  color: #000;
}

h1{
  color: #1c2428;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border: none;
  margin-top: 5px;
  margin-bottom: 10px;
}

.wrapper-blocks {
  /* clear: both; */
  position: relative;
  display: flex;
  height: calc(100vh - 98px);
  background-color: #f6f8fa;
  scrollbar-color: #c1bebe #ebeaea;;
  scrollbar-width: thin;
}

.wrapper-blocks.showedFilter{
  height: calc(100vh - 157px);
}

.block1 {
  border: 1px solid #dadee0;
  padding-left: 3px;
  position: relative;
  background-color: white;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  /* transition: linear width 0.3s; */
}
/* .block1::before {
  content: '';
  width: 15px;
  height: 100%;
  position: absolute;
  right: 0;
  background: linear-gradient(90deg, rgb(255 255 255 / 0%) 25%,
    rgb(255 255 255 / 69%) 50%, #ffffff 100%);
  z-index: 2;
} */
.block1:hover {
  overflow-y: auto;
}
/* .block1.active {
  transition: width 0.3s;
} */
.block2 {
  /* overflow-y:auto; */
  background-color: #f6f8fa;
  border: 1px solid #dadee0;
  border-left: none;
  margin: 0px 10px 0px 10px;
  box-shadow: 5px -2px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.block3 {
  position: sticky;
  bottom: 0;
  display: block;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
  background: #F6F8FA;
  color: #444;
}
.block3-2 {
  position: sticky;
  bottom: 0;
  display: block;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
  background: white;
  color: #333;
  /* width: 100%; */
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin-left: 10px;
}
.btn-tab{
  display: inline-block;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 6px 12px;
}
.btn-tab2{
  position: absolute;
  right: 12px;
  display: inline-block;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}
.filter-depend {
  background-color: #e1e5e8d1;
  padding: 10px 0px 10px 0px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 5px 0px;
}
.btn-tab2__toggle {
  display: inline-block;
  vertical-align: top;
  padding: 0px 10px 0px 0px;
}
.btn-tab:hover{background:#eee;}
.btn-tab.active {
  background: #5981ce;
  color: #fff;
}
.full-width{
  width: 100%;
}
</style>
