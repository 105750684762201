<template>
  <h1 class="header1 margin-bottom" v-if="modeMain === 1">
    Массово изменить свойство выбранных товаров
  </h1>
  <div class="main-block">
    <div class="margin-bottom">
      <SelectCustom
        v-model="selectedOperation"
        :options="field"
        :def="defLbl"
        @change="clearAllData"
      />
    </div>
    <div class="main-block_body" v-if="selectedOperation !== false">
      <div class="block margin-bottom main-block__title">
        {{ selectedOperation.lbl }}
      </div>
      <div v-for="(field, index) in selectedOperation.fields" :key="index">
        <div v-if="field.type == 'L'" class="form-field margin-bottom">
          <SelectCustom v-model="field.value"
            class="w100"
            :def="field.placeholder"
            :lbl="field.lbl"
            :options="field.options"
            :err="field.error"
            :req="field.required"
            :ind="index"
            @change="clearError"
            @outError="outError" />
        </div>

        <div v-if="field.type == 'I'" class="form-field margin-bottom">
          <input-text v-model="field.value"
            @keypress="validateInput"
            :def="field.placeholder"
            :lbl="field.lbl"
            :err="field.error"
            :req="field.required"
            :ind="index"
            @change="clearError"
            @outError="outError" />
        </div>

        <div class="margin-bottom" v-if="field.type == 'Z'">
          <div class="margin-bottom">
            <SelectCustom v-model="field.value"
              class="w100"
              :def="field.placeholder"
              :lbl="field.lbl"
              :options="field.options"
              :err="field.error"
              @change="clearError"
              />
          </div>

          <template v-if="field.value.fields">
            <div v-for="(field2, ind2) in field.value.fields" :key="ind2">
              <template v-if="field2.type == 'I'">
                <input-text v-model="field2.value"
                  @keypress="validateInput"
                  @outError="outError"
                  :def="field2.placeholder"
                  :lbl="field2.lbl"
                  :err="field2.error"
                  :ind="index"
                  :req="field2.required" />
              </template>
            </div>
          </template>
        </div>
        <div v-if="selectedOperation.operation === 'link_supp_prod'" class="el--form margin-bottom">
          <div v-for="(el, ind) in field.options" :key="ind"
            :class="{'l-1': el.type == 'L', 'i-1': el.type == 'I'}" >
            <template v-if="el.type == 'L'">
              <SelectCustom v-model="el.value"
                class="grid-operations"
                :def="el.placeholder"
                :lbl="el.lbl"
                :options="el.options"
                :err="el.error"
                @change="clearError" />
            </template>
            <template v-if="el.type == 'I'">
              <input-text v-model="el.value"
                @outError="outError"
                @keypress="validateInput($event, ind)"
                :def="el.placeholder"
                :lbl="el.lbl"
                :err="el.error"
                :ind="index"
                :req="el.required" />
            </template>
          </div>
          <div @click="addPack()" @keydown="addPack()"
            class="plitka el--form_button">
              <span class="over-text">{{ addNameButton }}</span>
          </div>
        </div>
        <template v-if="selectedOperation.operation === 'link_supp_prod'">
          <div class="el--form margin-bottom" v-for="(el, ind) in this.packCommon" :key="ind">
            <div class="block l-1">{{el[0].value.lbl}}</div>
            <div class="block i-1">{{el[1].value}}</div>
            <div class="block i-1">{{el[2].value}}</div>
            <div @click="removePack(ind)" @keydown="removePack(ind)"
              class="plitka el--form_button">
                <span>{{ removeNameButton }}</span>
            </div>
          </div>
        </template>
      </div>

      <!-- Удаление поставщиков -->
      <div v-if="selectedOperation.operation === 'supp_all_unlink'"></div>

      <!-- Кнопка -->
      <template v-if="selectedOperation.btn">
        <div v-if="selectedOperation.btn.show" class="wb-btn-sh wb-btn-success margin-bottom"
          @click="runOperation()"
          @keydown.f7="runOperation()" >
          {{selectedOperation.btn.lbl}}
        </div>
      </template>

      <!-- Ответ -->
      <div v-if="answer.length > 0">
        {{answer}}
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import SelectCustom from '@/components/v2/cross/form/fields/SelectDef.vue';
import InputText from '@/components/v2/cross/form/fields/InputText.vue';

export default {
  name: 'OperationsManage',
  props: {
    action: {
      type: String,
      default: '',
    },
    stock: {
      type: Number,
      default: 0,
    },
    field: {
      type: Array,
    },
    prods: {
      type: Array,
    },
    cats: {
      type: Array,
    },
    modeMain: {
      type: Number,
      default: 0,
    },
  },
  components: {
    SelectCustom,
    InputText,
  },
  data() {
    return {
      success: false,
      answer: '',
      fieldCleared: [],
      packCommon: [],
      selectedOperation: false,
      defLbl: 'Выберите операцию',
      currentValOperation: 0,
      request: 'post',
      error: '',
      errors: [],
    };
  },
  computed: {
    addNameButton() {
      // if (this.modeMain === 1) {
      //   return 'Добавить';
      // }

      // return '+';
      return 'Добавить';
    },
    removeNameButton() {
      // if (this.modeMain === 1) {
      //   return 'Удалить';
      // }

      // return '-';
      return 'Удалить';
    },
  },
  methods: {
    init() {
      axios.post(this.action, {
        stock: this.stock,
        cats: this.cats,
        prods: this.prods,
        field: this.selectedOperation,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.answer = res.data.answer;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    clearAllData() {
      this.answer = '';
      this.error = '';
    },
    outError() {
      this.$emit('outError');
    },
    validateInput(event, ind) {
      const field = this.selectedOperation.fields[0];
      let typeChgCu = false;
      let linkSuppProd = false;
      let valid = '';

      if (this.selectedOperation.operation === 'type_chg_cu') {
        field.value.fields[0].error = '';
        typeChgCu = true;
        valid = field.value.fields[0].valid;
      } else if (this.selectedOperation.operation === 'link_supp_prod') {
        field.options[ind].error = '';
        linkSuppProd = true;
        valid = field.options[ind].valid;
      } else {
        field.error = '';
        valid = field.valid;
      }

      if ((event.charCode > 57 || event.charCode < 48) && valid === 'number') {
        if (typeChgCu) {
          field.value.fields[0].error = 'Вводить можно только числа!';
        } else if (linkSuppProd) {
          field.options[ind].error = 'Вводить можно только числа!';
        } else {
          field.error = 'Вводить можно только числа!';
        }

        event.preventDefault();
      }
    },
    validate() {
      if (this.selectedOperation.operation === 'supp_all_unlink') {
        return true;
      }

      this.answer = '';
      const field = this.selectedOperation.fields[0];
      let valid = '';
      let value = '';

      if (this.selectedOperation.operation === 'type_chg_cu') {
        if (this.selectedOperation.fields[0].value.val === 0) {
          // this.selectedOperation.fields[0].error = 'Выберите операцию';
          return false;
        }
        if (!Object.prototype.hasOwnProperty.call(this.selectedOperation.fields[0].value.val, 'field')) {
          this.error = '';
          value = 'Good';
        }
        if (Object.prototype.hasOwnProperty.call(this.selectedOperation.fields[0].value.val, 'field')) {
          value = field.value.fields[0].value.trim();
          valid = field.value.fields[0].valid;
          field.value.fields[0].error = '';
        }
      } else if (this.selectedOperation.operation === 'link_supp_prod') {
        value = [];
        valid = [];

        field.value.forEach((valueOne) => {
          valueOne.forEach((el) => {
            if (el.type === 'I') {
              if (typeof el.value === 'string') {
                value.push(el.value.trim());
              } else {
                value.push(el.value);
              }
              valid.push(el.valid);
            }
          });
        });

        field.options.forEach((el) => {
          el.error = '';
        });
      } else if (field.type === 'I') {
        if (typeof field.value === 'string') {
          value = field.value.trim();
        } else {
          value = field.value;
        }
        valid = field.valid;
        field.error = '';
      } else {
        value = 'Для других операции, просто проход до конца';
        valid = 'Для других операции, просто проход до конца';
        field.error = '';
      }

      if (value === '') {
        this.error = 'пустая строка';
        console.log('пустая строка');
        return false;
      }

      if (valid === 'number' && !/^\d+$/.test(value)) {
        this.error = 'Вводить можно только числа!';
        return false;
      }

      if (Array.isArray(value)) {
        for (let index = 0; index < value.length; index += 1) {
          const valueOne = value[index];
          const validOne = valid[index];

          if (valueOne === '') {
            this.error = 'пустые строки';
            return false;
          }

          if (validOne === 'number' && !/^\d+$/.test(valueOne)) {
            this.error = 'Вводить можно только числа!';
            return false;
          }
        }
      }

      this.error = '';
      return true;
    },
    runOperation() {
      if (!this.validate()) {
        // console.log(this.validate());
        return;
      }
      this.answer = '';

      let cats = [];
      let prods = [];

      if (this.modeMain === 1) {
        prods = this.prods;
      }
      if (this.modeMain === 2) {
        cats = this.cats;
      }

      axios.post(this.action, {
        stock: this.stock,
        cats,
        prods,
        field: this.selectedOperation,
      })
        .then((res) => {
          if (res.data.success === 1) {
            if (res.data.answer.success === 0) {
              this.answer = res.data.answer.answer;
            } else {
              this.answer = res.data.answer;
            }
            this.$emit('answer', {
              success: res.data.success,
              operation: this.selectedOperation,
              prods,
            });
            this.$emit('upd-products', this.selectedOperation);
          } else {
            alert('Произошла ошибка выполнения операции');
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    clearError() {
      this.error = '';
    },
    addPack() {
      const el = JSON.parse(JSON.stringify(this.selectedOperation.fields[0].options));
      this.packCommon.push(el);

      if (this.packCommon.length) {
        this.selectedOperation.btn.show = true;
      } else {
        this.selectedOperation.btn.show = false;
      }

      this.selectedOperation.fields[0].value = this.packCommon;
    },
    removePack(ind) {
      this.packCommon.splice(ind, 1);

      if (this.packCommon.length) {
        this.selectedOperation.btn.show = true;
      } else {
        this.selectedOperation.btn.show = false;
      }

      this.selectedOperation.fields[0].value = this.packCommon;
    },
  },
};
</script>
<style scoped>
.header1 {
  color: #1c2428;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border: none;
  padding: 10px 12px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
}
.main-block {
  font-weight: 400;
  font-size: 14px;
  color: #444;
  padding: 1px 11px;
  background: #fbfbfb;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.main-block_body {
  padding: 0 0px 10px 0px;
}
.margin-bottom-small {
  margin-bottom: 10px;
}
.margin-bottom {
  margin-bottom: 10px;
}
.margin-bottom-big {
  margin-bottom: 20px;
}
.over-text {
  /* position: absolute; */
  white-space: nowrap;
  overflow:hidden
}
.over-text:hover {
  overflow: visible;
}
.main-block .block {
  padding: 10px 2px;
  position: relative;
  border-radius: 2px;
  white-space: nowrap;
  overflow-x: hidden;
}
.plitka {
  padding: 8px 12px;
  position: relative;
  border-radius: 2px;
  cursor: pointer;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.fit-grid > * {
    min-width: 0;
    max-width: 100%;
}
.el--form {
  display: grid;
  grid-template-columns: auto 100px 100px 100px;
  /* grid-template-columns: auto-fit , minmax(100px, 1fr); */
  column-gap: 10px;
}
.l-1 {
  min-width: 0px;
}
.block.l-1 {
  padding-left: 2px;
  align-content: center;
}
.block.i-1 {
  padding-left: 3px;
  align-content: center;
}
.el--form_button {
  text-align: center;
  padding: 20px 12px
}
.wb-btn-success {
  background: linear-gradient(to bottom, #6dc895 0%,#09c050 100%);
}
.wb-btn-success:hover {
  background: linear-gradient(to bottom, #6dc89d 0%,#09c07b 100%);
}
.mode2.main-block .block{
  padding: 4px 12px;
}
.mode2.main-block .margin-bottom {
  margin-bottom: 10px;
}
.mode2.main-block .margin-bottom-big {
  margin-bottom: 10px;
}
.mode2.main-block .el--form{
  display: grid;
  /* column-gap: 10px; */
  row-gap: 10px;
  grid-template-columns: 100px 110px 90px;;
  /* grid-template-rows: 1fr 1fr 1fr; */
}
.mode2.main-block .l-1{
  grid-column-start: 1;
  grid-column-end: 4;
  /* grid-row-start: 1;
  grid-row-end: 3; */
}
/* .mode2.main-block .i-1{
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 3;
}
.mode2.main-block .i-2{
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}
.mode2.main-block .el--form_button {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
} */
</style>
