<template>
  <div class="constructor">
    <div class="title" v-if="title.length > 0">{{title}}</div>
    <div class="wrp-block" v-for="(el, ind) in pack" :key="ind">
      <component :is="el.component" v-bind="el.conf"></component>
    </div>
  </div>
</template>

<script>
import ChartLiner from '@/components/v2/cross/chart/ChartLiner.vue';
import LabelsDef from '@/components/v2/cross/unique/LabelsDef.vue';
import OrderProduct from '@/components/v2/cross/unique/OrderProduct.vue';

export default {
  name: 'ConstructorDef',
  props: {
    pack: {
      type: Array,
      required: true,
      default: () => {},
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    ChartLiner,
    LabelsDef,
    OrderProduct,
  },
  data() {
    return {
      run: false,
      txt: '',
      showBtns: true,
    };
  },
  mounted() {
    console.log('конструктор создан');
  },
  methods: {
  },
};
</script>

<style scoped>
.title{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.wrp-block{
  background: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.wrp-body.with-title{
  height: calc(100vh - 46px);
}
.title{
  color: #1c2428;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border: none;
  padding: 10px 12px;
}
</style>
