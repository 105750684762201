<template>
  <div class="filter-top">
    <div style="text-align:right">
      <InputDiapazon
        :key="keyFilter"
        v-model:first="filter.firstDate"
        v-model:second="filter.secondDate"
        @update:second="upd"></InputDiapazon>
    </div>
  </div>
</template>

<script>
import InputDiapazon from '@/components/v2/cross/form/fields/InputDiapazon.vue';

export default {
  name: 'filterDef',
  props: {
    pack: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: {
    InputDiapazon,
  },
  emits: ['upd'],
  data() {
    return {
      keyFilter: 0,
      filter: {
        firstDate: 0,
        secondDate: 0,
      },

      errors: [],
    };
  },
  mounted() {
    this.filter = this.pack;
    this.keyFilter += 1;
  },
  methods: {
    upd() {
      this.$emit('upd', this.filter);
    },
  },
};
</script>

<style scoped>
.filter-top{
  padding: 0px 5px;
}
</style>
