<template>
    <table>
      <tr>
        <th :title="el.title" v-for="(el, ind) in head" :key="ind">
          <template v-if="el.context.type == 'icon'">
            <i class="fa" :class="el.context.tex" aria-hidden="true"></i>
          </template>
          <template v-if="el.context.type == 'html'">
            {{el.context.tex}}
          </template>
        </th>
      </tr>
      <tr class="block-elem" v-for="(el, ind) in list" :key="ind" :class="{odd: ind%2 == 0}">
        <th>{{el.name}}</th>
        <td>{{el.users}}</td>
        <td>{{el.stocks}}</td>
        <td>{{el.supps}}</td>
        <td>{{el.cats}}</td>
        <td>{{el.prods}}</td>
        <td>{{el.units}}</td>
        <td>{{el.services}}</td>
        <td>{{el.cprods}}</td>
        <td>{{el.mrsupps}}</td>
        <td>{{el.autorules}}</td>
        <td>{{el.orders}}</td>
        <td>{{el.arch}}</td>
      </tr>
    </table>
</template>

<script>
import axios from 'axios';

export default {
  name: 'DB',
  data() {
    return {
      list: [],
      head: [],
    };
  },
  mounted() {
    this.getListCompany();
  },
  methods: {
    getListCompany() {
      axios.get('/god/company/info')
        .then((res) => {
          this.list = res.data.pack;
          this.head = res.data.head;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
  th{
    text-align: left;
    background-color: #6090b6;
    color: #fff;
    font-weight: normal;
  }
  th,td{padding: 4px;}
  .odd{background-color: #e1e0e5;}
  .block{text-align:left; padding:12px; border: 1px solid #acbeca; display:inline-block;
    background: #d3e6f2;
    border: 1px solid #acbeca;
  }
  .block-title{font-size:18px; line-height:22px;}
  .block-list{padding:10px 0px 0px;}
  .block-elem{padding-bottom:4px;}

  .line-stick{display:inline-block; vertical-align:top; padding:0px 3px;}
</style>
