<template>
  <h1>
    <span>Сезоны/акции</span>
  </h1>
  <MenuTab
    :els="tabs"
    @activeTab="selTab"
  >
    <component :is="currentTabComponent" v-bind="currentOptions" :key="keyComponent"></component>
  </MenuTab>
</template>

<script>
import axios from 'axios';
import MenuTab from '@/components/v2/cross/menu/MenuTab.vue';
import ListManage from '@/components/v2/cross/list/ListManage.vue';

export default {
  name: 'IfacePromotions',
  components: {
    MenuTab,
    ListManage,
  },
  data() {
    return {
      filterSet: '',

      keyComponent: 0,
      currentTab: '',
      currOptions: {},
      tabs: [],
    };
  },
  mounted() {
    this.setTabs();
  },
  computed: {
    currentTabComponent() {
      return `${this.currentTab}`;
    },
    currentOptions() {
      return this.currOptions;
    },
  },
  methods: {
    selTab(tab) {
      this.currentTab = tab.name;
      this.currOptions = tab.options;
      this.keyComponent += 1;
    },
    setTabs() {
      axios.get('/v2/promotion/for-tabs')
        .then((res) => {
          if (res.data.success === 1) {
            this.tabs = res.data.tabs;
            this.currentTab = res.data.component.name;
            this.currOptions = res.data.component.options;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.tab-content{
  border-top: 1px solid #5981ce;
  padding: 15px 0px;
}
.btn-tab{
  display: inline-block;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  padding: 6px 12px;
  background: #e7e7e7;
  transition: background-color 0.3s;
}
.btn-tab:hover{background-color:#d0d0d0;}
.btn-tab.active {
  background: #5981ce;
  color: #fff;
}
h1{
  color: #1c2428;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border: none;
  margin-top: 5px;
  margin-bottom: 10px;
}
h1 span{
  background: transparent;
  padding: 0px 12px;
}
</style>
