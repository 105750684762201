<template>
  <div class="form-header">
    <div class="form-title" v-if="title">{{title}}</div>
  </div>
  <transition name="fade">
  <Loader v-if="spiner" />
  <div v-else class="body-context">
    <div class="form-field">
      <component :is="form[0].component" v-bind="form[0].conf"
        v-model="form[0].value" @update:modelValue="showBtn"></component>
    </div>
    <div class="form-field w50">
      <component :is="form[1].component" v-bind="form[1].conf"
        v-model="form[1].value" @update:modelValue="showBtn"></component>
    </div>
    <div class="form-field w50">
      <component :is="form[2].component" v-bind="form[2].conf"
        v-model="form[2].value" @update:modelValue="showBtn"></component>
    </div>
    <div class="form-field">
      <div class="title title-date">Время действия акции</div>
      <component :is="form[3].component" v-bind="form[3].conf"
        v-model:first="form[3].value"
        v-model:second="form[3].value2" @update:modelValue="showBtn"></component>
    </div>

    <div class="form-field area-prods">
      <div class="title">Выбор множества товаров, участвующих в акции</div>

      <div class="form-field w50">
        <component :is="form[4].component" v-bind="form[4].conf"
          v-model="form[4].value" @update:modelValue="loadLinkData"></component>
      </div>
      <div class="form-field" v-if="form[4].value.val > 0">
        <Loader v-if="loaderStock"></Loader>
        <component v-else :is="form[5].component" v-bind="form[5].conf"
          v-model="form[5].options" ></component>
      </div>

      <div style="display:flex" v-if="form[5].options">
        <div class="wrapper-three-list" v-show="form[5].options[0].chk">
          <ListBySearchCheck
            :list="cats"
            :mode="1"
            :tab="0"
            :key="keyLoad"
            lbl="Категории товаров"
            @changeStage="reculcProd"
          ></ListBySearchCheck>
        </div>
        <div class="wrapper-three-list" v-show="form[5].options[1].chk">
          <ListBySearchCheck
            :list="prods"
            lbl="Продукция"
            :tab="1"
            :key="keyLoad"
            @changeStage="reculcProd"
          ></ListBySearchCheck>
        </div>
        <div  class="wrapper-three-list" v-show="form[5].options[2].chk">
          <ListBySearchCheck
            :list="supps"
            lbl="Поставщики"
            :tab="2"
            :key="keyLoad"
            @changeStage="reculcProd"
          ></ListBySearchCheck>
        </div>
      </div>

      <div class="b-glass b-success" v-if="currProd.length">
        Выбранно товаров: {{currProd.length}}
        Из них не успеют к акции : 0
        <button class="btn-show-modal" @click="showModal">Подробнее</button>
      </div>
      <div class="b-glass b-warning" v-else>
        Нет выбранных товаров для акции
      </div>
    </div>

    <div style="margin-bottom: 8px">
      Параметры изменяющие ЦУ на время действия акции ЦУ = k*ЦУ или Новый ЦУ
    </div>
    <div class="form-field b-flex">
      <div class="w23">
        <component :is="form[7].component" v-bind="form[7].conf"
          v-model="form[7].value" @update:modelValue="showBtn"></component>
      </div>
      <div class="separated">или</div>
      <div class="w23">
        <component :is="form[6].component" v-bind="form[6].conf"
          v-model="form[6].value" @update:modelValue="showBtn"></component>
      </div>
    </div>

    <div class="form-field area-prods" v-if="currSup.length">
      <div class="title">
        Настройка <strong>времени пополнения</strong> для поставщиков товара, на время акции.<br />
        Если поля оставить пустыми, то время пополнения останется текущим
      </div>

      <div class="list-supps">
        <div class="one-line" v-for="(el, ind) in currSup" :key="ind">
          <InputText v-model="el.val2"
            :lbl="`${el.lbl} (${el.rt}д.)`"
            type="number"
            placeholder="Введите время пополнения"
            @update:modelValue="showBtn"
            ></InputText>
        </div>
      </div>
    </div>

    <a v-if="flagShowBtn" @click="submit"
      @keydown.f9="submit" class="btn-cross fix-right-bottom">{{btn_lbl}}</a>
    <transition name="fade">
      <div v-if="message.length > 0" class="form-answer"
        :class="{danger: !flagSuccess}">{{this.message}}</div>
    </transition>
  </div>
  </transition>
  <transition name="fade">
    <ModalRight v-show="flagModalRight"
      :title="modalTitle"
      @close="flagModalRight = false;" :class="modalClass">
      <component :is="modalComponent" v-bind="modalConf"
        :key="keyComponent" @answer="chgList"></component>
    </ModalRight>
  </transition>
</template>

<script>
import axios from 'axios';

import Loader from '@/components/v2/cross/loader/Loader.vue';
import SelectDef from '@/components/v2/cross/form/fields/SelectDef.vue';
import InputText from '@/components/v2/cross/form/fields/InputText.vue';
import CheckDef from '@/components/v2/cross/form/fields/CheckDef.vue';
import LabelList from '@/components/v2/cross/form/fields/LabelList.vue';
import InputDiapazon from '@/components/v2/cross/form/fields/InputDiapazon.vue';
import SelectMultyCheck from '@/components/v2/cross/form/fields/SelectMultyCheck.vue';
import ListBySearchCheck from '@/components/v2/cross/list/ListBySearchCheck.vue';
import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import ListManage from '@/components/v2/cross/list/ListManage.vue';

export default {
  name: 'FormPromotion',
  components: {
    Loader,
    SelectDef,
    InputText,
    CheckDef,
    LabelList,
    InputDiapazon,
    SelectMultyCheck,
    ListBySearchCheck,
    ModalRight,
    ListManage,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    init_action: {
      type: String,
      default: '',
    },
    body_action: {
      type: Object,
      required: false,
      default: () => {},
    },
    btn_action: {
      type: String,
      default: '',
    },
    btn_lbl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: [],
      saveFields: [],
      flagShowBtn: true,
      flagSuccess: 0,
      message: '',
      flagArea: [
        0,
        0,
        0,
      ],
      cats: [],
      prods: [],
      supps: [],

      flagModalRight: false,
      modalComponent: 'ListManage',
      modalConf: {},
      modalTitle: '',
      modalClass: '',
      keyComponent: 0,
      keyLoad: 0,

      errors: [],
      spiner: true,
      loaderStock: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    currProd() {
      if (!this.form[5].options) {
        return [];
      }

      const catCurr = [];
      if (this.form[5].options[0].chk) {
        for (let i = 0; i < this.cats.length; i += 1) {
          if (this.cats[i].chk === true) {
            catCurr.push(this.cats[i].val);
          }
        }
      }
      const suppCurr = [];
      if (this.form[5].options[2].chk) {
        for (let i = 0; i < this.supps.length; i += 1) {
          if (this.supps[i].chk === true) {
            suppCurr.push(this.supps[i].val);
          }
        }
      }
      let prodCurr = [];
      for (let i = 0; i < this.prods.length; i += 1) {
        if (this.prods[i].chk === true) {
          prodCurr.push(this.prods[i]);
        }
      }

      if ((this.form[5].options[0].chk === 0
      && this.form[5].options[2].chk === 0
      && this.form[5].options[1].chk === 0)
      || (suppCurr.length === 0
      && catCurr.length === 0
      && prodCurr.length === 0)) {
        return [];
      }

      if (prodCurr.length === 0) {
        prodCurr = this.prods;
      }

      if (catCurr.length === 0 && suppCurr.length === 0) {
        const ids = [];
        for (let i = 0; i < prodCurr.length; i += 1) {
          if (prodCurr[i].chk === true) {
            ids.push(this.prods[i].val);
          }
        }
        return ids;
      }

      const itemCurr = [];
      for (let i = 0; i < prodCurr.length; i += 1) {
        let f = 0;
        if (catCurr.length > 0) {
          f += 1;
          if (catCurr.includes(prodCurr[i].category_id)) {
            f -= 1;
          }
        }
        if (suppCurr.length > 0) {
          f += 1;
          if (suppCurr.includes(prodCurr[i].sid)) {
            f -= 1;
          }
        }
        if (f === 0) {
          itemCurr.push(prodCurr[i].val);
        }
      }
      return itemCurr;
    },
    currSup() {
      const suppIDs = [];
      for (let i = 0; i < this.prods.length; i += 1) {
        if (this.currProd.includes(this.prods[i].val)
        && !suppIDs.includes(this.prods[i].sid)) {
          suppIDs.push(this.prods[i].sid);
        }
      }
      return this.supps.filter((el) => suppIDs.includes(el.val));
    },
  },
  methods: {
    submit() {
      this.spiner = true;
      if (!this.validateReq()) {
        this.spiner = false;
        return false;
      }

      if (this.btn_action.length < 10) {
        this.spiner = false;
        return false;
      }

      axios.post(this.btn_action, { form: this.saveFields })
        .then((res) => {
          this.spiner = false;
          this.flagSuccess = res.data.success;
          this.message = res.data.answer;
          if (res.data.success === 1) {
            this.flagShowBtn = false;
            this.$emit('answer', res.data);
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
      return true;
    },
    validateReq() {
      this.saveFields = {};
      let noError = true;
      let i = this.form.length;
      while (i) {
        i -= 1;
        const el = this.form[i];
        if (el.component === 'InputDiapazon') {
          if (el.conf.required && (el.value === 0 || el.value2 === 0)) {
            this.form[i].conf.err = 'Поле не должно быть пустым';
            noError = false;
          }
          this.saveFields[el.conf.name] = {
            value: el.value,
            value2: el.value2,
          };
        } else if (el.component === 'SelectDef') {
          if (el.conf.required && el.value.val === 0) {
            this.form[i].conf.err = 'Поле не должно быть пустым';
            noError = false;
          }
          this.saveFields[el.conf.name] = el.value.val;
        } else if (el.component === 'SelectMultyCheck') {
          if (el.conf.required) {
            noError = false;
            this.form[i].conf.err = 'Один из вариантов должен быть выбран';

            for (let j = 0; j < el.conf.options.length; j += 1) {
              if (el.conf.options[j].chk === 1 || el.conf.options[j].chk === true) {
                noError = true;
                this.form[i].conf.err = '';
                break;
              }
            }
          }
          this.saveFields[el.conf.name] = el.conf.options;
        } else {
          if (el.conf.required && el.value === '') {
            this.form[i].conf.err = 'Поле не должно быть пустым';
            noError = false;
          }
          this.saveFields[el.conf.name] = el.value;
        }
      }

      if (this.saveFields.cu_new === '' && this.saveFields.k === '') {
        noError = false;
      }

      if (this.currProd.length === 0 || this.currSup.length === 0) {
        noError = false;
      }

      this.saveFields.sel_ids_prods = this.currProd;
      this.saveFields.rt = this.currSup;

      this.saveFields.prod_ids = [];
      for (let i = 0; i < this.prods.length; i += 1) {
        if (this.prods[i].chk === true) {
          this.saveFields.prod_ids.push(this.prods[i].val);
        }
      }
      this.saveFields.cats_ids = [];
      for (let i = 0; i < this.cats.length; i += 1) {
        if (this.cats[i].chk === true) {
          this.saveFields.cats_ids.push(this.cats[i].val);
        }
      }
      this.saveFields.supp_ids = [];
      for (let i = 0; i < this.supps.length; i += 1) {
        if (this.supps[i].chk === true) {
          this.saveFields.supp_ids.push(this.supps[i].val);
        }
      }

      return noError;
    },
    showModal() {
      console.log(1);
      this.keyComponent += 1;
      this.modalConf = {
        action: '/v2/product/list/',
        outerFilter: {
          stock: this.form[4].value.val,
          c_product: this.currProd,
        },
      };
      this.modalTitle = 'Товары участвующие в акции';
      this.modalClass = 'w80';
      this.flagModalRight = true;
    },
    init() {
      if (!this.init_action) return;
      axios.get(this.init_action)
        .then((res) => {
          this.form = res.data.form;
          this.btn = res.data.btn;
          this.spiner = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    showBtn() {
      this.message = '';
      this.flagShowBtn = true;
    },
    loadLinkData() {
      if (!this.form[4].value.val) return;
      this.loaderStock = true;
      axios.get(`/v2/promotion/load-stock-data?stock=${this.form[4].value.val}`)
        .then((res) => {
          this.cats = res.data.cats;
          this.prods = res.data.prods;
          this.supps = res.data.supps;
          this.spiner = false;
          this.loaderStock = false;
          this.keyLoad += 1;
        })
        .catch((e) => {
          this.loaderStock = false;
          this.errors.push(e);
        });
    },
    reculcProd(list, tab) { // попробовать через computed
      if (tab === 0) {
        this.cats = list;
      }
      if (tab === 1) {
        this.prods = list;
      }
      if (tab === 2) {
        this.supps = list;
      }
    },
    creOrder() {
      console.log('cre_order');
      axios.post(this.btn_action)
        .then((res) => {
          this.flagSuccess = res.data.success;
          this.message = res.data.answer;
          this.flagShowBtn = false;
          // if (res.data.success === 1) {
          // }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>

<style scoped>
.list-supps .one-line{
  width: 30%;
}
.btn-show-modal{
  display: inline-block;
  cursor: pointer;
}
.b-glass{
  margin-top: 16px;
  background-color: #ddd;
  padding: 8px;
  transition: .15s;
}
.b-success{
  background-color: #bcede855;
  border: 1px solid #84afaa;
}
.b-warning{
  background-color: #ffbaba55;
  border: 1px solid #e7aaaa;
}

.wrapper-three-list{
  height: 300px;
  /*overflow-y: auto; */
  overflow: hidden;
}
.area-prods{
  background: #eee;
  margin-left: -14px;
  margin-right: -14px;
  padding: 14px 14px;
}
.title{
  margin-bottom: 10px;
}
.title-date{
  color: #707070;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}
.w50{
  width: 50%;
}
.w23{
  width: 23%;
}
.separated{
  padding: 0px 12px;
  height: 56px;
  line-height: 71px;
}
.b-flex{
  display: flex;
}
.form-field{
  margin-bottom: 20px;
}
.progress.progress-striped{
  margin-bottom: 20px !important;
  width: 50%;
}
.form-header{
  position: relative;
  z-index: 1;
}
.body-context{
  height: calc(100vh - 112px);
  position: relative;
  overflow-y: auto;
  padding: 20px 14px 46px;
  background-color: #fff;
}
.btn-cross{
  border: none;
  border-radius: 2px;
  display: inline-block;
  font-size: 17px;
  height: 36px;
  line-height: 36px;
  outline: 0;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: #fff;
  background-color: #009688;
  text-align: center;
  letter-spacing: .5px;
  transition: 0.2s ease-out;
  cursor: pointer;
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: all 0.3s ease-out;

  position: fixed;
  right: 10px;
  bottom: 10px;
}
.btn-cross:hover{
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
table.table, table.info {
    border-spacing: 0px;
    border-collapse: collapse;
}
.table, table.info {
  border-collapse: collapse;
  margin-bottom: 20px;
}
.table {
  background: #fff;
  width: 99%;
  margin-bottom: 50px;
}

table.info{width: 50%;}
table.info td{
  padding: 7px;
  border-bottom: 1px solid #dadee0;
}
table.info th{
  font-weight: normal;
  text-align: left;
  background: #f6f8fa;
  white-space: nowrap;
  padding: 7px;
  border-bottom: 1px solid #dadee0;
}

.table thead th{
  font-weight: 100;
  text-align: left;
  background: #f6f8fa;
  font-size: 11px;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  vertical-align: top;

  border-right: 1px solid #dadee0;
  border-top: 1px solid #dadee0;
  padding: 7px 7px;
  height: 13px;
  border-bottom: 1px solid #6dc2ed;
}

.table tbody td{
  text-align: left;
  border-bottom: 1px solid #dadee0;
  padding: 7px 7px;
  font-size: 13px;
  white-space: nowrap;
}

.stock{
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 13px;
}

input{
  display: inline-block;
  width: 100%;
  min-width: 200px;
  /* padding: 4px 6px; */
  /* border-radius: 0 4px 4px 0px; */
  /* border: 1px solid #aaa; */
  /* border-left: 4px solid #aaa; */
  font-size: 12px;
  line-height: 18px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #dadee0;
  transition: all 0.3s;
  padding-left: 3px;
}
input:focus{
  border-color: #999;
}

.form-answer{
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #bbf7cb;
  border: 1px solid #8fbd9c;
  padding: 13px 46px;
}
.form-answer.danger {
  background: #fddbdb;
  border: 1px solid #9d8989;
}
.form-title{
  color: #1c2428;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border: none;
  padding: 10px 12px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
</style>
