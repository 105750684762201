<template>
  <div class="ext-settings">
    <div class="one-line plate-white" v-if="showPagination">
      <div class="counter" v-if="crit_resource.tek_cu && crit_resource.tek_cu !== '0'">
        <span class="counter_el left">
          По тек. ЦУ: {{ crit_resource.tek_cu }} {{ crit_resource.unit }}</span>
        <span class="counter_el">
          По рек. ЦУ: {{ crit_resource.rec_cu }} {{ crit_resource.unit }}</span>
      </div>
      <span>{{(pagination.page - 1) * pagination.limit}}</span>
      <span> - </span>
      <span v-if="(pagination.cnt - (pagination.page - 1) * pagination.limit) > pagination.limit">
        {{(pagination.page - 1) * pagination.limit + pagination.limit}}
      </span>
      <span v-else>{{pagination.cnt}}</span>
      <span> из </span>
      <span>{{pagination.cnt}}</span>
    </div>
  </div>
  <transition name="fade">
    <LoaderTreeSpin v-if="loaderBody" />
    <table v-else-if="list.length" class="table">
      <thead>
        <tr class="tr-head">
          <th v-for="(el, ind) in head" :key="ind"
          :title="el.lbltitle"
          :class="{hover: el.sort.show && ind > 0, active: el.sort.active > 0,
            'btn-filter': ind === 0, 'btn-active': showFilter && ind === 0}"
          @click="sort(ind)">
            <div class="th-inner one-line">
              <MenuCollapse v-if="ind === 0 && tableMenu.hasOwnProperty('params')"
                class="small"
                :ind="5000"
                :params="tableMenu.params"
                @showParentComponent="showParentComponent"
                @showComponent="showForm"
                @chgList="chgList"
              />
              <i v-if="ind > 0 && el.sort.show"
                class="fa"
                :class="{
                  'i-color': el.sort.active == 0,
                  'fa-sort-amount-asc': el.sort.val == 0,
                  'fa-sort-amount-desc': el.sort.val == 1
                }"
                aria-hidden="true"></i>

              <span v-if="el.type === 0">{{el.lbl}}</span>
              <span v-if="el.type === 1" v-html="el.lbl" class="th-icon"
                :style="{'color': el.color}" ></span>
            </div>
          </th>
        </tr>
        <tr v-if="tablefilter.length">
          <th :title="el.title" v-for="(el, ind) in tablefilter" :key="ind">
            <div class="th-inner two-line">
              <template v-if="ind === 0">
                <i class="fa fa-filter" aria-hidden="true"></i>
              </template>
              <template v-if="fieldCheckbox && ind === 1">
                <Checkbox class="list-manage" v-model="allChecked" @change="switchAllCheck" />
              </template>
              <div v-if="el.type === 'I2'" style="position: relative">
                <div v-if="summBadgeVal != 0" class="all-badge">
                  {{summBadgeVal > 0 ? '+':''}}{{summBadgeVal}}
                </div>
                <div class="button-table"
                  @click="saveChangedCu"
                  @keydown.f9="saveChangedCu">Сохранить</div>
              </div>
              <InputTextShort v-if="el.type == 'I'"
                v-model="el.value"
                @change="chgSubFilter"
                />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(fields, index) in list" :key="index"
          :class="{disabled: fields[0].params.list[0].type_vyvod}">
          <td v-for="(field, index2) in fields" :key="index2">
            <template v-if="field.type === 'M'">
              <MenuCollapse v-if="outerFilter && outerFilter.mode !== 0"
               class="right"
               :ind="index"
               :params="field.params"
                @showComponent="showForm"
                @chgList="chgList"
              />
              <div v-else class="context-menu-empty" :title="textEmptyMenu">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
              </div>
              <div class="classs" aria-hidden="true"></div>
            </template>
            <template  v-if="field.type === 'SL'">
              <div aria-hidden="true">
                <span v-if="el.level === 2"><i class="fa fa-share"></i></span>
                <span v-if="el.level > 2">&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-share"></i></span>
                {{field.value}}
              </div>
            </template>
            <template  v-if="field.type === 'C'">
              <div aria-hidden="true">
                <Checkbox class="list-manage" v-model="field.checked"
                  @change="changeIdsOperations(field.value, field.checked)" />
              </div>
            </template>
            <template v-if="field.type === 'N'">
              <div aria-hidden="true" :title="field.value" class="borderAfter">
                {{field.value}}
              </div>
            </template>
            <template v-if="field.type === 'S'">
              <div aria-hidden="true" :title="field.value">
                {{field.value}}
              </div>
            </template>
            <template v-if="field.type === 'I2'">
              <div style="overflow: inherit;" :title="field.value.val">
                <span @click="putCurrCu(index, index2)"
                  @keydown.f9="putCurrCu(index, index2)"
                  class="list-manage_button">></span>
                <label :for="`la${index}`" class="wb-input-text input-cu">
                  <span class="changed-cu plus"
                    v-show="field.value.badge != 0"
                    >{{field.value.badge}}</span>
                  <input
                    :id="`la${index}`"
                    style="width: 60px"
                    v-model="field.value.val"
                    @input="calcRecCu(index, index2)"
                    type="text"
                    autocomplete="off"/>
                </label>
              </div>
            </template>
            <template v-if="field.type === 'S2'">
              <div aria-hidden="true" :title="field.value.title">
                {{field.value.title}}
                <div class="sub-sett">
                  <div class="" v-for="(el3, ind3) in field.value.noset" :key="ind3">
                    {{el3}}
                  </div>
                </div>
              </div>
            </template>
            <template v-if="field.type === 'BO'">
              <div class="field-icon">
                <i v-if="field.value > 0" class="fa fa-check" aria-hidden="true"></i>
                <i v-else class="fa fa-times" aria-hidden="true"></i>
              </div>
            </template>
            <template v-if="field.type === 'B'">
              <div class='progress progress-striped'>
                <div class='progress-bar' :class="field.opt.color"
                  role='progressbar' :aria-valuenow='field.lbl'
                  aria-valuemin='0' aria-valuemax='100'
                  :style='{width: field.opt.w_block}'>
                  <span class='sr-only'>{{field.lbl}}</span>
                </div>
                <div v-if="field.opt.color_text"
                  class="buffer-lbl"
                  :class="field.opt.color_text" >{{field.value}} %</div>
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="list-empty">
      <div v-if="!emptyListMenu.hasOwnProperty('type')" class="text-empty">{{emptyListTxt}}</div>
      <div v-else-if="emptyListMenu.params.list[0]">
        <button class="empty-list-btn" @click="emptyOpenModal(emptyListMenu.params.list[0])">
          <i class="fa f-icon" :class="emptyListMenu.params.icon" aria-hidden="true"></i>
          <i class="fa t-icon" :class="emptyListMenu.params.list[0].icon" aria-hidden="true"></i>
          <span class="empty-btn-text">{{emptyListMenu.params.list[0].title}}</span>
        </button>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <ModalRight v-show="flagModalRight"
      :title="modalTitle"
      @close="flagModalRight = false;" :class="modalClass">
      <component :is="modalComponent" v-bind="modalConf"
        v-if="flagModalRight" @answer="chgList"></component>
    </ModalRight>
  </transition>
  <div v-if="pagination.cnt && pagination.pages.length > 1"
    class="wrapper-pagination">
    <div class="pagination-new">
      <div v-for="(el, ind) in pagination.pages" :key="ind"
        class="pagination-item" :class="{active: pagination.page == el.lbl}"
        @click="pagLoad(el)"
        @keydown.f7="pagLoad(el)">{{el.lbl}}
      </div>
    </div>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex';
import axios from 'axios';
import InputTextShort from '@/components/v2/serj/InputTextShort.vue';
// import MiniBurgerMenu from '@/components/v2/serj/MiniBurgerMenu.vue';
import MenuCollapse from '@/components/v2/cross/menu/MenuCollapse.vue';
import ModalRight from '@/components/v2/cross/modal/ModalRight.vue';
import Form from '@/components/v2/cross/form/Form.vue';
import HorizontalMenuTab from '@/components/v2/cross/group/HorizontalMenuTab.vue';
import LoaderTreeSpin from '@/components/loader/LoaderTreeSpin.vue';
import ConstructorDef1 from '@/components/v2/cross/ii/ConstructorDef.vue';
import Operations from '@/components/v2/serj/OperationsManage.vue';
import Checkbox from '@/components/v2/serj/Checkbox.vue';
import ManageOrder from '@/components/v2/cross/unique/ManageOrder.vue';
import LoadFileForChange from '@/components/v2/cross/form/LoadFileForChange.vue';
import FormPromotion from '@/components/v2/cross/unique/FormPromotion.vue';

export default {
  name: 'ListManage',
  components: {
    InputTextShort,
    MenuCollapse,
    ModalRight,
    Form,
    HorizontalMenuTab,
    LoaderTreeSpin,
    Checkbox,
    ConstructorDef1,
    Operations,
    ManageOrder,
    LoadFileForChange,
    FormPromotion,
  },
  props: {
    action: {
      type: String,
      default: '',
    },
    outerFilter: {
      type: Object,
      default() {
        return {};
      },
    },
    fieldCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['gotidsProdsOperations', 'showParentComponent'],
  data() {
    return {
      crit_resource: {
        tek_cu: 0,
        rec_cu: 0,
        unit: 'шт.',
      },
      // actionRecCu: '',
      textEmptyMenu: 'Меню недоступно. Элемент должен быть настроен',
      allChecked: false,

      flagAllowChangeNoset: false,

      idsOperations: [],
      l_curr_stock: localStorage.getItem('stock'),
      flagModalRight: false,
      modalComponent: '',
      modalConf: '',
      modalClass: 'w50',
      currIndex: -1,

      filter: {},
      list: [],
      head: [],
      tablefilter: [],
      tableMenu: {},
      modalTitle: '',

      instrumental: [],

      showFilter: false,

      currParam: {},
      currProduct: {},
      // currIndex: [],

      pagination: {},

      modeView: 0,
      headSetkaProducts: [],
      listSetkaProducts: [],
      tabsView: [],

      answerText: '',
      errText: '',
      errors: [],

      loader: true,
      loaderFilter: true,
      loaderBody: true,

      cntAll: 0,

      viewGroup: {},
      loadModal: false,
      showModalGroup: false,
      // End iFaceProduct
      emptyListTxt: 'Список пуст',
      emptyListMenu: {},
      summBadgeVal: 0,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    showPagination() {
      return this.list.length;
    },
  },
  methods: {
    init() {
      this.loaderBody = true;

      axios.post(this.action, { filter: this.outerFilter })
        .then((res) => {
          if (res.data.success === 1) {
            // this.filter = res.data.filter;
            this.head = res.data.list.head;
            this.list = res.data.list.items;
            this.tablefilter = res.data.list.filter;
            if (res.data.crit_resource) {
              this.crit_resource = res.data.crit_resource;
            }
            if (res.data.ext_filter) {
              this.showExtParam = true;
            }
            if (res.data.list.menu) {
              this.tableMenu = res.data.list.menu;
            }
            if (res.data.emptytext) {
              this.emptyListTxt = res.data.emptytext;
            }
            if (res.data.emptyList) {
              this.emptyListMenu = res.data.emptyList;
            }

            // this.headSetkaProducts = res.data.headSetkaProducts;
            // this.listSetkaProducts = res.data.listSetkaProducts;
            // this.tabsView = res.data.tabsView;
            // this.modeView = res.data.tabsViewActive;

            this.pagination = res.data.list.pagination;

            // this.instrumental = res.data.instrumental;
          }
          this.loaderBody = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    emptyOpenModal(data) {
      this.modalClass = 'w50';
      if (data.w) {
        this.modalClass = data.w;
      }
      this.flagModalRight = true;
      this.modalComponent = data.component;
      this.modalConf = data.conf;
    },
    saveChangedCu() {
      const pack = [];

      this.list.forEach((row) => {
        row.forEach((el) => {
          if (el.name === 'order' && parseInt(el.value.val, 10) > 0 && el.value.val2 > 0) {
            pack.push(
              {
                val: el.value.val2,
                lbl: el.value.val,
                val2: el.value.val3,
              },
            );
          }
        });
      });

      if (pack.length === 0) {
        alert('Необходимо указать новые ЦУ');
        return;
      }

      this.modalClass = 'w50';
      this.flagModalRight = true;
      this.modalComponent = 'Form';
      this.modalConf = {
        title: 'Причина изменения ЦУ',
        init_action: `/v2/cu/form-chg?stock=${this.outerFilter.stock}`,
        body_action: {
          cu: pack,
        },
        btn_action: `/v2/cu/form-chg-save?stock=${this.outerFilter.stock}`,
        btn_lbl: 'Изменить ЦУ',
      };
    },
    calcRecCu(ind, ind2) {
      const inf = this.list[ind][ind2].value;
      this.list[ind][ind2].value.badge = 0;
      if (!/^[0-9]*$/.test(inf.val)) {
        this.list[ind][ind2].value.val = '';
        this.list[ind][ind2].value.badge = 0;
      } else if (inf.val > 0) {
        this.list[ind][ind2].value.badge = (inf.val - inf.cu) * inf.crit;
      }

      this.summBadge();
    },
    putCurrCu(ind, ind2) {
      const inf = this.list[ind][ind2].value;
      this.list[ind][ind2].value.badge = (inf.rec_cu - inf.cu) * inf.crit;
      this.list[ind][ind2].value.val = inf.rec_cu;
      this.summBadge();
    },
    summBadge() {
      let summ = 0;
      this.summBadgeVal = 0;
      this.list.forEach((row) => {
        row.forEach((el) => {
          if (el.type === 'I2') {
            summ += el.value.badge;
          }
        });
      });

      this.summBadgeVal = summ;
    },
    switchAllCheck() {
      this.idsOperations = [];

      this.list.forEach((row) => {
        row.forEach((el) => {
          if (el.type === 'C') {
            if (this.allChecked) {
              this.idsOperations.push(el.value);

              el.checked = true;
            }
            if (!this.allChecked) {
              el.checked = false;
            }
          }
        });
      });
    },
    changeIdsOperations(value, chk) {
      if (chk === 1) {
        this.idsOperations.push(value);
      } else {
        // Удаляет элемент из массива, если попадется одинаковый
        this.idsOperations = this.idsOperations.filter((valueChk) => valueChk !== value);
      }

      this.$emit('gotidsProdsOperations', this.idsOperations);
    },
    showParentComponent(data) {
      const conf = {};
      if (data.component === 'Operations') {
        if (this.idsOperations?.length) {
          this.modalClass = 'w50';
          conf.field = this.outerFilter.operations;
          conf.stock = this.outerFilter.stock;
          conf.prods = this.idsOperations;
          conf.modeMain = 1;
          conf.action = '/v2/product/multi-set';
          this.modalComponent = data.component;
          this.modalConf = conf;
          this.flagModalRight = true;
        } else {
          alert('Выбери товары для применения операции');
        }
      }

      this.$emit('showParentComponent', data);
    },
    sort(ind) {
      if (!this.head[ind].sort.show || ind < 1) {
        return;
      }
      if (this.head[ind].sort.active) {
        if (this.head[ind].sort.val === 0) {
          this.head[ind].sort.val = 1;
        } else {
          this.head[ind].sort.val = 0;
        }
        this.loadList();
      } else {
        let i = this.head.length;
        while (i) {
          i -= 1;
          if (i === ind) {
            this.head[ind].sort.active = 1;
          } else {
            this.head[i].sort.active = 0;
          }
        }
        this.loadList(1);
      }
    },
    chgSubFilter() {
      this.loadList(1);
    },
    loadList(pag) {
      axios.post(this.action, {
        filter: this.outerFilter,
        extfilter: this.tablefilter,
        sort: this.head,
        page: pag,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.list = res.data.list.items;
          }
          this.pagination = res.data.list.pagination;
          this.loaderBody = false;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    pagLoad(el) {
      this.loaderBody = true;
      if (this.pagination.page === el.lbl) { return; }
      this.pagination.page = el.lbl;

      this.loadList(el.lbl);
      /*
      setTimeout(() => {
        this.loadList();
      }, 100);
      */
    },
    act(elem) {
      if (elem.type === 0) {
        this.$router.push(elem.act);
      }
    },
    test(data) {
      this.loader = data;
    },
    removeTr(index) {
      this.tbody.splice(index, 1);
    },
    showForm(options) {
      this.modalClass = options.w;
      this.modalComponent = options.component;

      if (Object.prototype.hasOwnProperty.call(options.conf, 'stock')) {
        options.conf.stock = this.outerFilter.stock;
      }
      if (Object.prototype.hasOwnProperty.call(options.conf, 'cats')) {
        options.conf.cats = this.outerFilter.c_category;
      }

      console.log(options.conf);
      this.modalConf = options.conf;
      this.currIndex = options.ind;
      this.flagModalRight = true;
    },
    chgList(data) {
      if (Object.prototype.hasOwnProperty.call(data, 'ind')) {
        this.currIndex = data.ind;
      }
      if (data.act === 'chg') {
        this.list.splice(this.currIndex, 1, data.elem);
      } else if (data.act === 'add') {
        this.list.unshift(data.elem);
      } else if (data.act === 'del') {
        this.list.splice(this.currIndex, 1);
      }

      if (Object.prototype.hasOwnProperty.call(data, 'operation')) {
        if (data.success === 1) {
          if (data.operation.operation === 'mode') {
            if (data.operation.fields[0].value.val !== 0) {
              this.list.forEach((row) => {
                row.forEach((el) => {
                  if (el.type === 'C') {
                    if (data.prods.indexOf(el.value) !== -1) {
                      this.flagAllowChangeNoset = true;
                    }
                  }
                  if (el.type === 'S2' && this.flagAllowChangeNoset) {
                    el.value.noset = el.value.noset.filter((el2) => el2 !== 'тип контроля');
                    this.flagAllowChangeNoset = false;
                  }
                });
              });
            }

            if (data.operation.fields[0].value.val === 0) {
              this.list.forEach((row) => {
                row.forEach((el) => {
                  if (el.type === 'C') {
                    if (data.prods.indexOf(el.value) !== -1) {
                      this.flagAllowChangeNoset = true;
                    }
                  }
                  if (el.type === 'S2' && this.flagAllowChangeNoset) {
                    el.value.noset.push('тип контроля');
                    this.flagAllowChangeNoset = false;
                  }
                });
              });
            }
          }

          if (data.operation.operation === 'link_supp_prod' || data.operation.operation === 'supp_main') {
            this.list.forEach((row) => {
              row.forEach((el) => {
                if (el.type === 'C') {
                  if (data.prods.indexOf(el.value) !== -1) {
                    this.flagAllowChangeNoset = true;
                  }
                }
                if (el.type === 'S2' && this.flagAllowChangeNoset) {
                  el.value.noset = el.value.noset.filter((el2) => el2 !== 'поставщик');
                  this.flagAllowChangeNoset = false;
                }
              });
            });
          }

          if (data.operation.operation === 'supp_unlink' || data.operation.operation === 'supp_all_unlink') {
            this.list.forEach((row) => {
              row.forEach((el) => {
                if (el.type === 'C') {
                  if (data.prods.indexOf(el.value) !== -1) {
                    this.flagAllowChangeNoset = true;
                  }
                }
                if (el.type === 'S2' && this.flagAllowChangeNoset) {
                  el.value.noset.push('поставщик');
                  this.flagAllowChangeNoset = false;
                }
              });
            });
          }
        }
      }
    },
  },
  /*
  computed: {
    ...mapGetters('auth', {
      getterStock: 'getStock',
    }),
  },
  updated() {
    if (this.getterStock === '') {
      const stock = localStorage.getItem('stock');
      if (this.l_curr_stock !== stock) {
        this.l_curr_stock = stock;
        this.init();
      }
    } else {
      const stock = this.getterStock;
      if (this.l_curr_stock !== stock) {
        this.l_curr_stock = stock;
        this.init();
      }
    }
  },
  */
};
</script>

<style scoped>

.empty-list-btn{
  padding: 0px;
  background-color: #f6f8fa;
  box-shadow: none;
  border: 1px solid #dadee0;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow .25s, background-color 0.25s;
  font-size: 14px;
}
.empty-list-btn:hover{
  background-color: #f2f2f2;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.f-icon{padding: 10px 10px; background-color: #e2e5e8;}
.t-icon{padding-left: 10px;}
.empty-btn-text{
  padding: 10px;
}
.all-badge{
  position: absolute;
  right: 0px;
  top: 0px;
}
.wb-input-text.input-cu{
  border-bottom: 1px solid #aaa;
  display: inline-block;
  position: relative;
}
  .ext-settings_checkbox {
    display: inline-block;
    font-size: 14px;
    background-color: #ffffff;
    color: #1c2428;
    border: 1px solid #dadee0;
    line-height: 14px;
    padding: 4px 6px;
    margin-left: 50px;
  }
  .counter {
    display: inline-block;
    /* position: absolute; */
    font-size: 11px;
    margin-right: 10px;
    margin-bottom: -1px;
  }
  .counter_el {
    background: #f6f8fa;
    border: 1px solid #e2e6e8;
    padding: 6px;
  }
  .counter_el.left {
    margin-right: 5px;
  }
  .button-table {
    text-align: center;
    cursor: pointer;
    padding: 11px;
    margin: -7px;
  }
  .button-table:hover {
    background: #dfe4e6;
  }
  .button-table.success {
    background: #95e2ac;
    color: #14632c;
  }
  .changed-cu{
    position: absolute;
    top: -8px;
    right: 0px;
    border-radius: 2px;
    font-size: 11px;
  }
  .changed-cu.plus {
    /*
    color: #97e7c1;
    background-color: #193629e8;
    border: 1px solid #1d5e3f;
    padding: 0px 3px;
    */

    color: #236a41;
    background-color: #c7fbe3e8;
    padding: 0px 3px 1px;
  }
  .changed-cu.minus {
    color: #ffa9a9;
    background-color: #361a19e8;
    border: 1px solid #a00909;
  }
  .wb-input-text{
    position: relative;
    margin-left: 0px;
    /* padding-top: 16px; */
    display: inline;
  }
  .label{
    color: #909090;
    display:inline-block;
    position: absolute;
    top: 24px; left: 0px;
    transition: top 0.3s, left 0.3s, font-size 0.3s, color 0.3s;
  }
  input {
    display: inline-block;
    width: 80%;
    border: none;
    border: 1px solid #aaa;
    /* font-size: 13px; */
    line-height: 18px;
    outline: none;
    box-sizing: border-box;
    background: transparent;
    transition: border-bottom-color 0.3s, background-color 0.3s;
  }
.list-manage_button {
  cursor: pointer;
  padding: 2px 6px 1px 6px;
  border-bottom: 1px solid #aaa;
  text-align: center;
}
.list-manage_button:hover {
  background-color: #e8e8e8;
}

.context-menu-empty{
  top:0px; left:0px; right: 0px; bottom: 0px;
  text-align: center;
  opacity: 0.5;
}
.sub-sett > div{
  display: inline-block;
  vertical-align: top;
  background-color: #ffbebe;
  color: #2e1010;
  padding: 0px 3px;
  margin-right: 4px;
}
body table.table .progress.progress-striped{
  min-width: 180px;
  max-width: 260px;
}
table.table {
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
}
.table{
   background: #fff;
}
.container-up-table {
  display: flex;
  position: sticky;
  align-items: flex-end;
  flex-direction: column;
}
tr.disabled td{
  opacity: 0.3;
}
tr.disabled td:first-child{
  opacity: 1;
}

/*
table.table {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow .25s;
}
table.table:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
*/

.links {
}
.links_el {
}
.select-checkbox {
}
.hover {
  cursor: pointer;
}
.i-color {
  color: #b4b8bb;
}
.fa-share {
  transform: rotate(180deg);
  transform:scale(1, -1);
/*  transition: width 0.1s ease-out;*/
}

.slide-form-leave-active {
  transition: width 0.1s ease-in;
}
.slide-form-enter-from, .slide-form-leave-to{
  width: 200%;
}
.slide-form-enter-to, .slide-form-leave-from{
  width: 100%;
}
.instrumental-new {
  display: flex;
  position: sticky;
  top: 50px;
  width: 100%;
  justify-content: flex-end;
}
.instrumental-new .btn-add-new {
    background: #f6f8fa;
    font-size: 13px;
    padding: 6px;
    display: inline-block;
    cursor: pointer;
    top: 1px;
    border: 1px solid #dadee0;
    position: relative;
    transition: all 0.3s;
}
.instrumental-new .btn-add-new:hover {
    border: 1px solid #6dc2ed;
}
.wrapper-pagination {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  right: 0px;
  bottom: -2px;
}
.pagination-new {
  display: flex;
/*  background: #f6f8fa;*/
/*  border-radius: 7px;*/
/*  padding: 5px 5px;*/
/*  border: 1px solid #dadee0;*/
}
/* Begin */
.pagination-item {
  list-style-type: none;
  display: inline-block;
  border-top: 1px solid #d7dadb;
  border-right: 1px solid #d7dadb;
  font-size: 16px;
  line-height: 24px;
  padding: 6px 14px;
  color: #8b969c;
  background-color: white;
  cursor: pointer;
}
.pagination-item:first-child {
  border-left: 1px solid #d7dadb;
}

.pagination-item:hover {
  background-color: #DCDDE1;
  border-top: 1px solid #DCDDE1;
  border-right: 1px solid #DCDDE1;
}
.pagination-item.active {
  background-color: #bcede8;
  border-top: 1px solid #bcede8;
  border-right: 1px solid #bcede8;
  color: #333;
}

.pagination-item.first-number {
  border-left: 1px solid #d7dadb;
}

.pagination-link {
  padding: 1.1em 1.6em;
  display: inline-block;
  text-decoration: none;
  color: #8b969c;

  transform: scale(1) rotate(0deg) translateX(0px) translateY(0px) skewX(20deg) skewY(0deg);
}
/* End */
.pagination-new .pag-el-new.active {
/*    background-color: #44b752;*/
/*    border: 1px solid #2c9539;*/
  border: 1px solid #6dc2ed;
  background-color: #f6f4f4;
/*  background-color: #0000003d;*/
}
.pagination-new .pag-el-new {
/*    vertical-align: top;*/
    /* background-color: #749be5; */
    /* border: 1px solid #4c7fe1; */
    /* color: #fff; */
    cursor: pointer;
    padding: 12px 12px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border-radius: 100px;
    transition: all 0.3s;
    border: 1px solid #f6f8fa;
}
.pagination-new .pag-el-new:hover {
  border: 1px solid #6dc2ed;
}
.filter-hide td div{
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
}
.filter-show td div{
  height: 32px;
  overflow: hidden;
  transition: height 0.5s;
}
.short-input-filter{
  max-width: 200px;
  min-width: 100px;
}
.table-filter .short-input-filter{
  max-width: 100%
}
.table {
/*  width: 100%;*/
/*  border-left: 1px solid #dadee0;*/
  border-collapse: collapse;
/*  border-spacing: 0px;*/
}
.table thead {
  position: sticky;
  top: 0px;
  z-index: 1;
}
.table thead th {
  font-weight: 100;
  text-align: left;
  background: #f6f8fa;
  font-size: 11px;
  white-space: nowrap;

  padding: 0;
  margin: 0;
  vertical-align: top;
}
.table thead tr:last-child th{
  /* border-bottom: 1px solid #6dc2ed; */
}
.table thead tr:nth-child(n+2) th:first-child{
/*  border-right: 1px solid #dadee0;*/
  text-align: center;
}
.table thead tr th:first-child {
  border-top: none;
  border-left: none;
  width: 12px;
/*  border-radius: 8px 0 0 8px;*/

}
.table thead tr th:last-child {
/*  border-radius: 0 8px 8px 0;*/
  width: 100%;
}
.th-inner{
  border-right: 1px solid #dadee0;
  border-top: 1px solid #dadee0;
  padding: 7px 7px;
  height: 13px;

}
.th-inner.two-line{
  height: 21px;
  border-bottom: 1px solid #6dc2ed;
}
.fa-filter{
  font-size: 16px; line-height: 21px;
}
.table tbody td{
  text-align: left;
  border-bottom: 1px solid #dadee0;
  padding: 7px 7px;
  font-size: 13px;
  white-space: nowrap;
}
.table tbody tr:nth-child(even){
/*  background: #fdfcfccc;*/
}
.table tbody tr td:nth-child(2){
  border-right: 1px solid #dadee0;
  margin-bottom: 100px;
/*  width: 110px;*/
}

.table tbody tr td:nth-child(n+3) > div{
/*  border-right: 1px solid #dadee0;*/
  overflow: hidden;
  position: relative;
  display: block;
  padding-right: 5px;
  max-width: 500px;
  min-width: 100%;
}
.table tbody tr td:nth-child(n+3) > div:before{
  content: '';
  width: 10px;
  height: 30px;
  position: absolute;
  right: 0;
  background: linear-gradient(90deg,
    rgb(255 255 255 / 0%) 25%,
    rgb(255 255 255 / 69%) 50%,
    #ffffff 100%);
}
.table tbody tr td:first-child {
/*  border-radius: 8px 0 0 8px;*/
}
.table tbody tr td:last-child {
/*  border-radius: 0 8px 8px 0;*/
}
.table tr:hover {
  background-color: #fdfcfccc;
}
input[type=text] {
    border:none;
    background:none;
    outline:none;
    padding:0;
    font-size: 14px;
    font-weight: 100;
    color: #2c3e50;
}

.tab-el, .tab-el2 {
  border: 1px solid #f6f8fa;
  border-radius: 8px 0 0 8px;
  background-color: #f6f8fa;
  display: inline-block;
  padding: 10px 15px;
}

.tab-el {
}

.tab-el2 {
  margin: 0px 0px 15px 0px;
}
.field-icon{font-size: 18px;}
.field-icon .fa-check{color: green;}
.field-icon .fa-times{color: red;}

.ext-settings{
  overflow: hidden;
  /* width: 100%; */
}

.list-empty{
  display: flex;
  height: 100%;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;

  /* background: #ccc; */
  color: #1e1e1e;
  font-size: 16px;
}
.list-empty .text-empty{
  /* background: #fff; */
  text-align: center;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px #fff;

  background-color: #e2e5e8;
  padding: 6px 12px;
}
.new td {background-color: #e9faec}

.table tbody tr.new td div:before{
background: linear-gradient(90deg, rgb(233 255 236 / 0%) 25%,
rgb(233 255 236 / 69%) 50%, #e9faec 100%);
}
</style>
