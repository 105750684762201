<template>
  <div class="head">
    <div class="title-inner" v-if="titleInner">{{titleInner}}</div>
  </div>
  <div class="body">
    <div class="descr">
    <ol>
      <li>Для получения xlsx-файла с текущими ЦУ необходимо,
    нажать на соответствующую кнопку ниже. В файле присутствуют
    4 столбца (идентифкатор товара, артикул товара,
    наимнование товара, текущий ЦУ).</li>
      <li>Чтобы загрузить новые ЦУ для товаров, выбранного склада.
    Необходимо загрузить xlsx-файл формата схожего с файлом-шаблона
    (который такж можно скачать ниже). Обязательно первый столбец, каждой
    строки товара, должен содержать идентификатор товара "Гармонии",
    а в 4 столбце должен быть указан новый ЦУ,
    иначе ЦУ для товара не будет изменен на новый.</li>
    </ol>
    </div>
    <div class="setka">
      <div class="one-line">
        <button class="block-squade" @click="downloadFile">
          <i class="fa fa-cloud-download" aria-hidden="true"></i>
          <div class="squade-title">Получить шаблон с текущими ЦУ</div>
        </button>
      </div>
      <div class="one-line">
        <InputFile v-model="file" :accept="accept"
          @change="handleFileUpload"
          class="block-squade" v-if="!selectedFile" />
        <div class="block-squade flex" v-else-if="textAnswer.length == ''">
          <div>{{file.name}}
            <button @click="unselectFile">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <button @click="send" class="btn-info">Загрузить ЦУ</button>
        </div>
        <div v-else :class="{'alert-info': flagAnswer == 1, 'alert-danger': flagAnswer == 0}">
          {{textAnswer}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import InputFile from '@/components/v2/cross/input/InputFile.vue';

export default {
  name: 'LoadFileForChange',
  components: {
    InputFile,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    config: {
      type: Array,
      required: false,
      default: () => [],
    },
    stock: {
      type: Number,
      required: false,
      default: 0,
    },
    cats: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      titleInner: '',
      run: false,
      selectedFile: false,
      file: {},
      accept: '.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      textAnswer: '',
      flagAnswer: 0,
    };
  },
  mounted() {
    // document.body.style.overflowY = 'hidden';
    if (this.stock) {
      this.titleInner = 'Изменение ЦУ через XLSX-файл';
    }
  },
  methods: {
    downloadFile() {
      let data = {};
      let actionUrl = '';
      if (this.stock > 0) {
        data = {
          stock: this.stock,
          cats: this.cats,
        };
        actionUrl = '/v2/cu/get-xls-cu';
      } else {
        data = this.config;
        actionUrl = '/scm/cu/get-xls-cu';
      }

      axios.post(
        actionUrl,
        { config: data },
        {
          responseType: 'blob',
        },
      ).then((res) => {
        const url = URL.createObjectURL(new Blob([res.data], {
          type: 'application/vnd.ms-excel',
        }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sett_cu.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch(() => {
        console.log('FAILURE!!');
      });
    },
    handleFileUpload() {
      this.selectedFile = true;
    },
    unselectFile() {
      this.selectedFile = false;
    },
    send() {
      const formData = new FormData();
      formData.append('file', this.file);

      let stock = 0;
      let actionUrl = '';
      if (this.config.left) {
        if (this.config.left[0].value.val) {
          stock = this.config.left[0].value.val;
        } else {
          stock = this.config.left[0].value;
        }
        formData.append('stock', stock);
        actionUrl = '/scm/cu/set-xls-cu';
      } else {
        stock = this.stock;
        formData.append('stock', stock);
        formData.append('cats', this.cats);
        actionUrl = '/v2/cu/set-xls-cu';
      }

      axios.post(
        actionUrl,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ).then((res) => {
        this.textAnswer = res.data.answer;
        this.flagAnswer = res.data.success;
      }).catch(() => {
        console.log('FAILURE!!');
      });
    },
  },
};
</script>

<style scoped>
.title-inner{
  color: #1c2428;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  border: none;
  padding: 10px 12px;

  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.alert-info{
  background-color: #d7f7e0;
  border: 1px solid #c4ddcb;
  padding: 20px;
  color: #49524c;
}
.alert-danger{
  background-color: #f7d7d7;
  border: 1px solid #ddc4c4;
  padding: 20px;
  color: #524949;
}
ol{
  padding: 0px;
  margin: 0px;
  padding-left: 19px;
}
ol li{
  padding-bottom: 14px;
}
.setka{
  display: flex;
  flex-direction: column;
}
.descr{
  padding: 32px 25px 0px;
  line-height: 20px;
  font-size: 18px;
}
.one-line{
  padding: 20px 25px;
  transition: padding 0.3s;
}
.one-line:has(.block-squade:hover) {
    padding: 20px 20px 15px;
}

.block-squade{
  cursor: pointer;
  width: 100%;
  height: 200px;
  display: block;
  font-size: 26px;
  line-height: 28px;

  box-shadow: 0px 1px 2px 2px #cacaca;
  border: none;
  border-radius: 0px;
  background-color: #c3e4e3;
  transition: width 0.5s, height 0.5s;
}
.block-squade.flex{
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.block-squade.flex i{
  font-size: 28px;
  cursor: pointer;
  color: #d28c8c;
}
.block-squade i{
  font-size: 80px;
  color: #6dc2ed;
}
.squade-title{
  margin-top: 20px;
}
.block-squade:hover{
}
.block-squade.close{
  height: 0px;
}
.block-squade input{display:none;}
label{
  padding: 8px;
  box-sizing: border-box;
}
.inner-label{
  border: 1px dashed #aaa;
  height: 100%;
  text-align: center;
  background-color: #b7f1c3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.btn-info{
  width: 50%;
  font-size: 32px;
  line-height: 65px;
  margin-top: 20px;
  border: none;
  background-color: #6dc2ed;
  cursor: pointer;
  color: #fff;
}
</style>
