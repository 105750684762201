<template>
  <div class="wb-checkbox">
    <div class="squad" @click="check" @keydown="check">
      <span v-if="checkbox">&#10004;</span>
    </div>
    <div class="chk-lbl" v-html="text"></div>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props: [
    'text',
    'err',
    'modelValue',
  ],
  emits: ['update:modelValue', 'change'],
  data() {
    return {
    };
  },
  computed: {
    checkbox() {
      let flag = false;
      if (this.modelValue === '1' || this.modelValue === 1 || this.modelValue === true) {
        flag = true;
      }
      return flag;
    },
  },
  methods: {
    check() {
      let flag = 1;
      if (this.checkbox === true) {
        flag = 0;
      }
      this.$emit('update:modelValue', flag);
      this.$emit('change');
    },
  },
};
</script>

<style scoped>
  .list-manage.wb-checkbox {
    width: 20.5px;
  }
  .wb-checkbox{text-align:left;}
  .wb-checkbox.big{
    text-align: center;
  }
  .squad{
    width:18px;
    height:18px;
    border:1px solid #aaa;
    float:left;
    cursor:pointer;
    text-align:center;
    line-height:19px;
    border-radius:2px;
    background:#fff;
  }

  .wb-checkbox.big .squad{
    width:40px;
    height:40px;
    font-size:40px;
    line-height: 40px;
    margin: 0 auto;
    float: none;
  }

  .squad:hover{background:#efefef}
  .chk-lbl{margin-left:26px; line-height:21px;}
</style>
