<template>
  <div class="wb-list">
    <div class="list-head" v-if="lbl.length > 0">{{lbl}} ({{modelValue.length}})</div>
    <div class="list-body">
      <table class="table">
        <thead>
          <tr>
            <th><div class="th-inner">Продукт</div></th>
            <th><div class="th-inner">Новый ЦУ</div></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(el, ind) in modelValue" :key="ind">
            <td>{{el.name}}</td>
            <td style="text-align:right">{{el.new}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LabelList',
  props: {
    lbl: {
      type: String,
      default: '',
    },
    def: {
      type: String,
      default: '',
    },
    err: {
      type: String,
      default: '',
    },
    req: {
      type: Boolean,
      default: false,
    },
    ind: {
      type: Number,
      default: 0,
    },
    modelValue: {
      type: String,
    },
  },
  data() {
    return {
      show_ext_lbl: false,
      id: null,
      focus: false,
      errorText: 'start',
    };
  },
  mounted() {
    this.id = this.$.uid;
  },
};
</script>

<style scoped>
table.table{width: 100%;}
.list-body{
  max-height: 200px;
  overflow-y: auto;
}
.list-head{
  margin-bottom: 12px;
  color: #909090;
  top: 0px;
  left: 0px;
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  transition: top 0.3s, left 0.3s, font-size 0.3s, color 0.3s;
}
thead{
  position: sticky;
  top: 2px;
  z-index: 1;
}
th{
  font-weight: 100;
  text-align: left;
  background: #f6f8fa;
  font-size: 11px;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  vertical-align: top;
}
.th-inner{
  border-right: 1px solid #dadee0;
  border-top: 1px solid #dadee0;
  padding: 7px 7px;
  height: 13px;
}
td{
  text-align: left;
  border-bottom: 1px solid #dadee0;
  padding: 7px 7px;
  font-size: 13px;
  white-space: nowrap;
}
</style>
