<template>
  <div class="wb-select" :class="{'error-field': error.length > 0, 'req': req}">
    <div class="label" :class="{active: showList}" v-if="lbl">{{lbl}}</div>
    <div v-click-outside="closeSelect">
      <div class="wb-select-label"
        :class="{active: showList}"
        @click.stop="showList = !showList"
        @keydown.f9="showList = !showList">
        <div class="icon-right">
          <i v-if="showList" class="fa fa-chevron-up"></i>
          <i v-else class="fa fa-chevron-down"></i>
        </div>
        <div class="val-text">{{modelValue ? modelValue.lbl : def}}</div>
      </div>
      <transition name="fade">
        <div class="wb-select-list" v-if="showList">
          <div class="list--item" v-for="(option, index) in options" :key="index"
            @click="selectOption(option)"
            @keydown.f10="selectOption(option)">{{option.lbl}}</div>
        </div>
      </transition>
      <transition>
        <span class="error-input" v-if="err">
          {{error}}
        </span>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectDef',
  props: {
    lbl: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [
        {
          val: 0,
          lbl: 'Пустое поле',
        },
      ],
    },
    def: {
      type: String,
      default: '',
    },
    err: {
      type: String,
      default: '',
    },
    req: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showList: false,
      errorText: 'start',
    };
  },
  computed: {
    error() {
      let txt = '';
      if (this.errorText === 'start') {
        txt = this.err;
      } else {
        txt = this.errorText;
      }
      return txt;
    },
  },
  methods: {
    selectOption(option) {
      if (option.val !== 0) {
        this.errorText = '';
      } else if (this.req === 1 || this.req === true) {
        this.errorText = 'Поле не должно быть пустым';
      }
      this.showList = false;
      this.$emit('update:modelValue', option);
      this.$emit('change');
    },
    closeSelect() {
      this.showList = false;
    },
    onClose() {
      this.$emit('outError', this.ind);
    },
  },
};
</script>

<style scoped>
  .grid-operations.wb-select {
    min-width: 140px;
  }
  .grid-operations .wb-select-label{
    padding: 14.8px 2px 8px;
  }
  .grid-operations .val-text {
    overflow: hidden;
  }
  .wb-select{
    min-width: 210px;
    position: relative;
  }
  .wb-select-label{
    padding: 12px 2px 8px;
    white-space: nowrap;
    border-bottom: 1px solid #aaa;
    transition: padding 0.3s, box-shadow 0.3s, background-color 0.3s;
    cursor: pointer;
  }
  .wb-select-label.active{
    padding-left: 12px;
    padding-right: 12px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                        0 3px 1px -2px rgba(0, 0, 0, 0.12),
                        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                0 3px 1px -2px rgba(0, 0, 0, 0.12),
                0 1px 5px 0 rgba(0, 0, 0, 0.2);
    background: #d9d9d9;
  }
  .wb-select-label:hover{background-color: #e8e8e8;}
  .wb-select.w100 .val-text{max-width: 100%;}
  .wb-select-list{
    position: absolute;
    /* left: -4px; */
    top: 54px;
    /* margin-top: 10px; */
    min-width: 100%;
    max-height: 250px;
    background: #fff;
    /* border: 1px solid #aaa; */
    border-radius: 0px 0px 4px 4px;
    overflow-y: auto;
    z-index: 12;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                        0 3px 1px -2px rgba(0, 0, 0, 0.12),
                        0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                0 3px 1px -2px rgba(0, 0, 0, 0.12),
                0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border-bottom:1px solid #aaa;
  }
  .list--item{padding:8px 12px; transition: background 0.3s; cursor: pointer;}
  .list--item:hover{background:#ddd}

  .label{
    color: #909090;
    top: 0px; left: 0px;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    transition: top 0.3s, left 0.3s, font-size 0.3s, color 0.3s;
  }
  .label.active{
    color: #345F90;
  }

  /* W3C standard сейчас только для Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #848484 #d7d7d7;
  }
  /* для Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    background: #d7d7d7;
    width:3px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #848484;
  /*  border-radius: 5px; */
    border: 2px solid #d7d7d7;
  }
  .icon-right{
    float: right;
    margin-left: 5px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .error-field .label {
    color: #b33d3d;
  }
  .error-field input {
    border-bottom-color: #b33d3d;
  }
  .error-field .val-text{
    color: #b33d3d;
  }
  .error-input {
    color: #b33d3d;
    position: absolute;
    font-size: 12px;
    line-height: 14px;
    bottom: -16px;
    left: 0px;
  }

  .mw180{min-width: 180px;}

  .wb-select.small-size {
    font-size: 14px;
    display: inline-block;
  }
  .wb-select.small-size .label{
    display: none;
  }
  .wb-select.small-size .wb-select-label {
    padding: 2px 4px;
  }
  .wb-select.small-size .wb-select-list {
    top: 10px;
  }
  .wb-select.small-size .list--item {
    padding: 2px 4px;
  }
</style>
